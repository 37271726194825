<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <!-- แสดงภาพ -->
    <Photo v-if="ShowPhoto" :showphoto="ShowPhoto" :showphotolink="ShowPhotoLink" @emitshowphoto="ClosePhoto" />
    <v-row>
      <v-col class="text-right">
        <span>{{ percentLoadingText }} {{ percentLoading }}%</span>
      </v-col>
    </v-row>
    <v-card>
      <!-- ส่วนแบ่งแท็บไว้ทางซ้าย -->

      <v-row class="d-flex flex-nowrap overflow-x-auto">
        <div class="pr-0 col-md-2 col-4">
          <v-navigation-drawer permanent :mini-variant.sync="mini" v-model="drawer" style="width: 100%">
            <v-list-item @click.stop="mini = !mini">
              <v-row>
                <v-col cols="9" v-if="!mini">
                  <span>สถานะพัสดุ</span>
                </v-col>

                <v-col cols="3" class="text-right">
                  <v-icon>{{
                    mini ? "mdi-chevron-right" : "mdi-chevron-left"
                  }}</v-icon></v-col>
              </v-row>
            </v-list-item>

            <v-list-item v-for="items in tab_list" :key="items.id"
              :class="{ 'light-blue': selectedTab == items.statusID }" @click="getPackingType(items)">
              <table style="width: 100%">
                <td>
                  <v-row>
                    <v-list-item-icon>
                      <v-icon>{{ getIconPack(items.statusID) }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content v-if="!mini">
                      <v-list-item-title>
                        {{ items.statusName }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-row>
                </td>
                <td class="pt-3 text-right" v-if="!mini">
                  <v-badge color="grey" inline>
                    <template v-slot:badge>
                      <span>{{ items.statusCount }} </span>
                    </template>
                  </v-badge>
                </td>
              </table>
            </v-list-item>
          </v-navigation-drawer>
        </div>
        <div class="pl-0 col-md-10 col-8">
          <v-card outlined color="transparent" background-color="transparent">
            <!-- ส่วนของแท็บ -->
            <v-progress-linear
              color="blue"
              
              :indeterminate="progressBarActive"
            ></v-progress-linear>
            <v-card-text>
              
                  <v-row class="py-3">


                    <v-col cols="3">
                      <v-select v-model="search.select_topic" :items="search.select_header" persistent-hint
                        return-object dense outlined hide-details item-text="name" label="ค้นหาโดย"
                        item-value="value"></v-select>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field v-model="search.text_topic" placeholder="คำค้นหา" dense outlined hide-details />
                    </v-col>

                    <v-col cols="6">
                      <v-btn :elevation="1" color="primary" class="mr-2" dark @click="btnSearchOrder()">
                        <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
                        ค้นหา
                      </v-btn>
                      <v-btn @click="btnOpenAdvanceSearchDialog()" outlined class="mr-2">
                        <v-icon>mdi-filter-outline</v-icon>ขั้นสูง
                      </v-btn>
                      <v-btn @click="btnOpenSortDialog()" outlined class="mr-2">
                        <v-icon>mdi-swap-vertical</v-icon>จัดเรียง </v-btn>
                      <v-btn :elevation="1" outlined color="success"  @click="ExcelDialog=true">
                        <v-icon size="14" class="pr-1">fa-solid fa-download</v-icon>
                        <span class="fn-12"> Excel </span>
                        <span v-if="loadingExcel != 0">({{ loadingExcel }}%)</span>
                      </v-btn>

                    </v-col>


                  </v-row>
                
                  <v-divider v-if="selectedTab!=130"></v-divider>
                  <v-row  v-if="selectedTab!=130" class="py-3">
                    <v-col cols="3">
                      <v-autocomplete v-model="search.shop" placeholder="ร้านค้า" :items="search.shopList"  @input="btnSearchShop()"
                      item-text="shopName" item-value="shopID" outlined required return-object hide-details dense>
                      <template slot="selection" slot-scope="data">
                          {{ length60(data.item.shopName) }}
                          <v-badge v-if="data.item.shopCount" inline color="error" :content="data.item.shopCount" />
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ length60(data.item.shopName) }}
                          <v-badge v-if="data.item.shopCount" inline color="error" :content="data.item.shopCount" />
                        </template>
                    </v-autocomplete>

                      <!-- <v-select v-model="search.shop" :items="search.shopList" item-text="shopName" item-value="shopID"
                        persistent-hint return-object dense outlined label="ร้านค้า" hide-details
                        @input="btnSearchShop()">
                        <template slot="selection" slot-scope="data">
                          {{ length60(data.item.shopName) }}
                          <v-badge v-if="data.item.shopCount" inline color="error" :content="data.item.shopCount" />
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ length60(data.item.shopName) }}
                          <v-badge v-if="data.item.shopCount" inline color="error" :content="data.item.shopCount" />
                        </template>
                      </v-select> -->
                    </v-col>
                    <v-col cols="3">
                      <v-select label="ช่องทาง" v-model="search.channelSource" :items="search.channelSourceList"
                        item-text="channelName" item-value="channelID" dense return-object hide-details outlined
                        @input="btnChannelSource(search.channelSource)">
                        <template slot="selection" slot-scope="data">
                          <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                            style="width: 25px" />
                          {{ length60(data.item.channelName) }}
                          <v-badge v-if="data.item.channelCount" inline color="error"
                            :content="data.item.channelCount" />
                        </template>
                        <template slot="item" slot-scope="data">
                          <img v-if="data.item.channelLogo != null" :src="data.item.channelLogo" class="mr-2"
                            style="width: 25px" />
                          {{ length60(data.item.channelName) }}
                          <v-badge v-if="data.item.channelCount" inline color="error"
                            :content="data.item.channelCount" />
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="3">
                      <v-select label="ขนส่ง" v-model="search.select_exprees" :items="search.exprees"
                        item-text="expressCompanyName" item-value="expressCompanyID" dense return-object hide-details
                        outlined @input="btnExExprees(search.select_exprees.expressCompanyID)">
                        <template slot="selection" slot-scope="data">
                          <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                            class="mr-2" style="width: 45px" />
                          {{ length60(data.item.expressCompanyName) }}
                          <v-badge v-if="data.item.expressCount" inline color="error"
                            :content="data.item.expressCount" />
                        </template>
                        <template slot="item" slot-scope="data">
                          <img v-if="data.item.expressCompanyLogo != null" :src="data.item.expressCompanyLogo"
                            class="mr-2" style="width: 45px" />
                          {{ length60(data.item.expressCompanyName) }}
                          <v-badge v-if="data.item.expressCount" inline color="error"
                            :content="data.item.expressCount" />
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="3">
                      <v-select label="ประเภทการปริ้น" v-model="search.groupPrint" :items="search.groupPrintList"
                        item-text="name" item-value="id" dense return-object hide-details outlined
                        @input="btnSearchPrintType(search.groupPrint)">
                        <template slot="selection" slot-scope="data">
                          {{ length60(data.item.name) }}
                          <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ length60(data.item.name) }}
                          <v-badge v-if="data.item.count" inline color="error" :content="data.item.count" />
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="py-3">
                    <v-col cols="8">
                      <v-row>
                        <v-col cols="4" class="pr-0">
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                                <span class="pl-1">ดำเนินการ</span>
                                <v-icon right>mdi-chevron-down</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item v-for="item in showManageMenuList" :key="item.name"
                                @click="handleClickOutside(item)" :disabled="isDisabled(item)">
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                              </v-list-item>

                              <vue-excel-xlsx v-show="false" id="GetExcel" :data="dataTable.itemsExcel"
                                :columns="dataTable.headerExcel" :file-name="GetDate() + '_รายงานออเดอร์'"
                                :sheet-name="'reportOrderExcel'" />
                            </v-list>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="4">

                    </v-col>
                  </v-row>
                  <v-row class="text-center" align="center" v-if="dataTable.selected.length > 1">
                    <v-col cols="12">
                      <div style="background: rgb(235 235 235)">
                        <b>คุณเลือกออเดอร์ {{ dataTable.selected.length }} รายการในหน้านี้
                        </b>
                        <v-btn text @click="btnSelectedTarget()"
                          v-if="dataTable.selected.length != total && limits != 999999">
                          <a>เลือกออเดอร์ทั้ง {{ total }} ในรายการหลัก</a>
                        </v-btn>
                        <v-btn text @click="dataTable.selected = []" v-else>
                          <a>ล้างการเลือก</a>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <!-- ส่วนของ Data table -->
                  <v-row>
                    <v-col>
                      <v-data-table v-model="dataTable.selected" :headers="showHeaders" :items="dataTable.items"
                        item-key="orderMasterPackageInfoID" hide-default-footer :items-per-page="9999999" show-select
                        disable-sort class="elevation-1 packhai-border-table">
                        <template v-slot:item.startPackingDate="{ item }">
                          {{ formatDatetime(item.startPackingDate) }}
                        </template>
                        <template v-slot:item.packedDate="{ item }">
                          {{ formatDatetime(item.packedDate) }}
                        </template>
                        <template v-slot:item.confirmedOrderDate="{ item }">
                          {{ formatDatetime(item.confirmedOrderDate) }}
                        </template>

                        <template v-slot:item.createdDatetime="{ item }">
                          {{ formatDatetime(item.createdDatetime) }}
                        </template>
                        <template v-slot:item.dueDeliveryDate="{ item }">
                          {{ formatDatetime(item.dueDeliveryDate) }}
                        </template>
                        <template v-slot:item.readyToPackDate="{ item }">
                          {{ formatDatetime(item.readyToPackDate) }}
                        </template>
                        <template v-slot:item.endPackingDate="{ item }">
                          {{ formatDatetime(item.endPackingDate) }}
                        </template>
                        <template v-slot:item.receiverName="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" style="width: 250px" class="text-truncate">
                                <span>{{ item.receiverName }}</span>
                              </div>
                            </template>
                            <span>
                              <span>{{ item.receiverName }}</span>
                            </span>
                          </v-tooltip>
                        </template>
                        <template v-slot:item.channelName="{ item }">
                          <v-avatar size="25px" tile>
                            <v-img v-if="item.channelLogo != null && item.channelLogo != ''" :src="item.channelLogo" />
                          </v-avatar>
                        </template>
                        <template v-slot:item.expressCompanyName="{ item }">
                          <img v-if="item.expressLogo != null && item.expressLogo != ''" width="60px"
                            :src="item.expressLogo" />
                        </template>
                        <template v-slot:item.isInit="{ item }">
                          <v-icon v-if="item.isInit != null && item.isInit" size="10"
                            color="success">mdi-checkbox-blank-circle</v-icon>
                          <v-icon v-else-if="item.isInit != null && !item.isInit" size="10"
                            color="danger">mdi-checkbox-blank-circle</v-icon>
                        </template>
                        <template v-slot:item.isPrintPackingList="{ item }">
                          <v-icon v-if="item.isPrintPackingList" size="10"
                            color="success">mdi-checkbox-blank-circle</v-icon>
                          <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                        </template>
                        <template v-slot:item.isPrintAWB="{ item }">
                          <v-icon v-if="item.isPrintAWB" size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                          <v-icon v-else size="10" color="danger">mdi-checkbox-blank-circle</v-icon>
                        </template>
                        <template v-slot:item.id="{ item }">
                          <a @click="ShowDetailOrder(item.id, item.token)">{{
                            item.orderIDText
                          }}</a>
                        </template>
                        <template v-for="(header, index) in dataTable.header"
                          v-slot:[`header.${header.value}`]="{ header }">
                          <v-tooltip :key="index" v-slot:activator="{ on }">
                            <span>{{ header.text }}</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col cols="2">
                      <v-select v-model="limits" hide-details outlined :items="search.select_per" item-text="name"
                        item-value="value" @change="btnChangeLimits(), (latestLimitType = 1)">
                      </v-select>
                    </v-col>
                    <v-col cols="8" class="pa-0" center>
                      <v-pagination v-model="page" :length="pageLength" :total-visible="9" color="info"></v-pagination>
                    </v-col>
                  </v-row>
              
            </v-card-text>
          </v-card>
        </div>
      </v-row>
    </v-card>

    <v-dialog v-model="sortDialog" scrollable :max-width="sortDialogMaxWidth" persistent
      :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1">
        <v-card-title>
          <v-row>
            <v-col cols="6">
              ระบุวิธีจัดเรียงออเดอร์
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn icon @click="sortDialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>

        </v-card-title>
        <v-divider />

        <v-card-text>
          <v-row class="py-2">
            <v-col cols="3" class="mt-2">
              ลำดับที่ 1 :
            </v-col>
            <v-col cols="5">
              <v-select :items="sortAbleItem" label="จัดเรียงจาก" v-model="sortAbleSelected1" item-text="name"
                item-value="id" clearable dense hide-details density="compact"></v-select>
            </v-col>
            <v-col cols="4">
              <v-select :items="sortType" label="เรียงโดย" v-model="sortTypeSelected1" item-text="name" item-value="id"
                dense hide-details density="compact"></v-select>
            </v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="3" class="mt-2">
              ลำดับที่ 2 :
            </v-col>
            <v-col cols="5">
              <v-select :items="sortAbleItem" label="จัดเรียงจาก" v-model="sortAbleSelected2" item-text="name"
                item-value="id" clearable dense hide-details density="compact"></v-select>
            </v-col>
            <v-col cols="4">
              <v-select :items="sortType" label="เรียงโดย" v-model="sortTypeSelected2" item-text="name" item-value="id"
                dense hide-details density="compact"></v-select>
            </v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="3" class="mt-2">
              ลำดับที่ 3 :
            </v-col>
            <v-col cols="5">
              <v-select :items="sortAbleItem" label="จัดเรียงจาก" v-model="sortAbleSelected3" item-text="name"
                item-value="id" clearable dense hide-details density="compact"></v-select>
            </v-col>
            <v-col cols="4">
              <v-select :items="sortType" label="เรียงโดย" v-model="sortTypeSelected3" item-text="name" item-value="id"
                dense hide-details density="compact"></v-select>
            </v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="3" class="mt-2">
              ลำดับที่ 4 :
            </v-col>
            <v-col cols="5">
              <v-select :items="sortAbleItem" label="จัดเรียงจาก" v-model="sortAbleSelected4" item-text="name"
                item-value="id" clearable dense hide-details density="compact"></v-select>
            </v-col>
            <v-col cols="4">
              <v-select :items="sortType" label="เรียงโดย" v-model="sortTypeSelected4" item-text="name" item-value="id"
                dense hide-details density="compact"></v-select>
            </v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="3" class="mt-2">
              ลำดับที่ 5 :
            </v-col>
            <v-col cols="5">
              <v-select :items="sortAbleItem" label="จัดเรียงจาก" v-model="sortAbleSelected5" item-text="name"
                item-value="id" clearable dense hide-details density="compact"></v-select>
            </v-col>
            <v-col cols="4">
              <v-select :items="sortType" label="เรียงโดย" v-model="sortTypeSelected5" item-text="name" item-value="id"
                dense hide-details density="compact"></v-select>
            </v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="3" class="mt-2">
              ลำดับที่ 6 :
            </v-col>
            <v-col cols="5">
              <v-select :items="sortAbleItem" label="จัดเรียงจาก" v-model="sortAbleSelected6" item-text="name"
                item-value="id" clearable dense hide-details density="compact"></v-select>
            </v-col>
            <v-col cols="4">
              <v-select :items="sortType" label="เรียงโดย" v-model="sortTypeSelected6" item-text="name" item-value="id"
                dense hide-details density="compact"></v-select>
            </v-col>
          </v-row>
        </v-card-text>


        <v-divider />
        <v-card-actions>
          <v-row>
            <v-col cols="12" align="right">
              <v-btn color="primary" @click="CheckFilter()"> ดำเนินการ </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="advanceSearchDialog" scrollable :max-width="advanceDialogMaxWidth" persistent
      :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1">
        <v-card-title>
          <v-row>
            <v-col cols="6">
              ค้นหาขั้นสูง
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn icon @click="advanceSearchDialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>

        </v-card-title>
        <v-divider />

        <v-card-text>
          <v-row class="py-2" v-if="selectedTab==130">
            <v-col class="pb-1" cols="3" > ร้านค้า : </v-col>
            <v-col class="pb-1" cols="9" >
              <v-autocomplete v-model="search.shopDefault" placeholder="เลือกร้านค้า" :items="search.shopListDefault"
              item-text="shopName" item-value="shopID" outlined required return-object hide-details dense></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="py-2">
            <v-col class="pb-1" cols="3" > วันที่ยืนยันออเดอร์ : </v-col>
            <v-col class="pb-1" cols="9" >
              <v-row>
                <v-col cols="6">
                  <v-menu ref="menuStartDate3" v-model="menuCFDateFrom" :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="290px" max-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field 
                        v-model="CFDateFromText" 
                        outlined readonly label="เริ่มต้น"
                        prepend-inner-icon="mdi-calendar" 
                        @blur="CFDateFromSelected = parseDate(CFDateFromText)" 
                        v-bind="attrs"
                        v-on="on" 
                        hide-details 
                        clearable 
                        @click:clear="CFDateFromText = null,CFDateFromSelected=null"></v-text-field>
                    </template>
                    <v-date-picker 
                      v-model="CFDateFromSelected" 
                      @input="menuCFDateFrom = false"
                      @change="(CFDateFromText = formatDate(CFDateFromSelected))" 
                      no-title>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu ref="menuStartDate4" v-model="menuCFDateTo" :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="290px" max-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field 
                        v-model="CFDateToText" 
                        outlined readonly label="เริ่มต้น"
                        prepend-inner-icon="mdi-calendar" 
                        @blur="CFDateToSelected = parseDate(CFDateToText)" 
                        v-bind="attrs"
                        v-on="on" 
                        hide-details 
                        clearable 
                        @click:clear="CFDateToText = null,CFDateToSelected=null"></v-text-field>
                    </template>
                    <v-date-picker 
                      v-model="CFDateToSelected" 
                      @input="menuCFDateTo = false"
                      @change="(CFDateToText = formatDate(CFDateToSelected))" 
                      no-title>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="py-2">
            <v-col class="pb-1" cols="3" > วันที่พร้อมแพ็ก : </v-col>
            <v-col class="pb-1" cols="9" >
              <v-row>
                <v-col cols="6">
                  <v-menu ref="menuStartDate3" v-model="menuRPDFrom" :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="290px" max-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field 
                        v-model="ReadyPackDateFromText" 
                        outlined readonly label="เริ่มต้น"
                        prepend-inner-icon="mdi-calendar" 
                        @blur="ReadyPackDateFromSelected = parseDate(ReadyPackDateFromText)" 
                        v-bind="attrs"
                        v-on="on" 
                        hide-details 
                        clearable 
                        @click:clear="ReadyPackDateFromText = null,ReadyPackDateFromSelected=null"></v-text-field>
                    </template>
                    <v-date-picker 
                      v-model="ReadyPackDateFromSelected" 
                      @input="menuRPDFrom = false"
                      @change="(ReadyPackDateFromText = formatDate(ReadyPackDateFromSelected))" 
                      no-title>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu ref="menuStartDate4" v-model="menuRPDTo" :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="290px" max-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field 
                        v-model="ReadyPackDateToText" 
                        outlined readonly label="เริ่มต้น"
                        prepend-inner-icon="mdi-calendar" 
                        @blur="ReadyPackDateToSelected = parseDate(ReadyPackDateToText)" 
                        v-bind="attrs"
                        v-on="on" 
                        hide-details 
                        clearable 
                        @click:clear="ReadyPackDateToText = null,ReadyPackDateToSelected=null"></v-text-field>
                    </template>
                    <v-date-picker 
                      v-model="ReadyPackDateToSelected" 
                      @input="menuRPDTo = false"
                      @change="(ReadyPackDateToText = formatDate(ReadyPackDateToSelected))" 
                      no-title>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="py-2">
            <v-col class="pb-1" cols="3" > วันที่ต้องจัดส่ง : </v-col>
            <v-col class="pb-1" cols="9"  >
              <v-row>
                <v-col cols="6">
                  <v-menu ref="menuStartDate3" v-model="menuDueDeliveryFrom" :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="290px" max-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field 
                        v-model="DueDeliveryDateFromText" 
                        outlined readonly label="เริ่มต้น"
                        prepend-inner-icon="mdi-calendar" 
                        @blur="DueDeliveryDateFromSelected = parseDate(DueDeliveryDateFromText)" 
                        v-bind="attrs"
                        v-on="on" 
                        hide-details 
                        clearable 
                        @click:clear="DueDeliveryDateFromText = null,DueDeliveryDateFromSelected=null"></v-text-field>
                    </template>
                    <v-date-picker 
                      v-model="DueDeliveryDateFromSelected" 
                      @input="menuDueDeliveryFrom = false"
                      @change="(DueDeliveryDateFromText = formatDate(DueDeliveryDateFromSelected))" 
                      no-title>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu ref="menuStartDate4" v-model="menuDueDeliveryTo" :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="290px" max-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field 
                        v-model="DueDeliveryDateToText" 
                        outlined readonly label="เริ่มต้น"
                        prepend-inner-icon="mdi-calendar" 
                        @blur="DueDeliveryDateToSelected = parseDate(DueDeliveryDateToText)" 
                        v-bind="attrs"
                        v-on="on" 
                        hide-details 
                        clearable 
                        @click:clear="DueDeliveryDateToText = null,DueDeliveryDateToSelected=null"></v-text-field>
                    </template>
                    <v-date-picker 
                      v-model="DueDeliveryDateToSelected" 
                      @input="menuDueDeliveryTo = false"
                      @change="(DueDeliveryDateToText = formatDate(DueDeliveryDateToSelected))" 
                      no-title>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-card-text>


        <v-divider />
        <v-card-actions>
          <v-row>
            <v-col cols="12" align="right">
              <v-btn color="primary" @click="btnSearchOrder()"> ดำเนินการ </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- กรองสินค้า -->
    <v-dialog v-model="filterOrderPopup.dialog" scrollable :max-width="filterOrderPopup.MaxWidth" persistent
      :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
          <div class="v-list-item">
            <div class="pa-2">
              <span>
                <h5>ตัวกรองสินค้า</h5>
              </span>
            </div>
            <div class="v-list-item__content pa-2">
              <v-row>
                <v-col cols="6" />
                <v-col cols="6" class="text-right">
                  <vue-excel-xlsx :data="filteOrderDataSet.dataset" :columns="filteOrderDataSet.headerExcel"
                    :file-name="GetDate() + '_รายงานกรองสินค้า'" :sheet-name="'filterOrderExcel'" class="mr-2">
                    <v-btn :elevation="1" outlined color="success" class="pa-2" @click="btnExportExcelFilteOrder()">
                      <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
                      <span class="fn-12"> Export Excel </span>
                    </v-btn>
                  </vue-excel-xlsx>
                </v-col>
              </v-row>
            </div>
            <div>
              <v-btn icon @click="filterOrderPopup.dialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
            </div>
          </div>
        </v-card-title>

        <v-card class="overflow-x-auto hidden-xs-only">
          <v-card-text>
            <v-tabs v-model="filteOrderDataSet.tab">
              <v-tabs-slider></v-tabs-slider>
              <v-tab v-for="i in filteOrderDataSet.tab_list" :key="i.id" @click="filteOrderDataSet.tab = i.id">
                {{ i.name }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="filteOrderDataSet.tab" touchless>
              <v-tab-item v-for="i in filteOrderDataSet.tab_list" :key="i.id">
                <div v-if="i.id == 0">
                  <table>
                    <tr>
                      <td class="pl-1">จำนวน SKU ในออเดอร์ :</td>
                      <td class="pl-1">
                        <v-radio-group v-model="filteOrderDataSet.radio_sku" row hide-details>
                          <v-radio :label="items.name" v-for="items in filteOrderDataSet.radio" :key="items.id"
                            :value="items.id"></v-radio>
                        </v-radio-group>
                      </td>
                      <td class="pl-1">
                        <v-text-field class="text-center" style="width: 100px; text-align: center" type="number"
                          v-model="filteOrderDataSet.sku_start" placeholder="จาก" outlined hide-details dense
                          :disabled="!filteOrderDataSet.radio_sku" />
                      </td>
                      <td class="pl-1">
                        <span>ถึง</span>
                      </td>
                      <td class="pl-1">
                        <v-text-field class="text-center" style="width: 100px; text-align: center" type="number"
                          v-model="filteOrderDataSet.sku_end" placeholder="ถึง" outlined hide-details dense
                          :disabled="!filteOrderDataSet.radio_sku" />
                      </td>
                      <td class="pl-1">
                        <span>SKU</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pl-1">จำนวน ชิ้น ในออเดอร์ :</td>
                      <td class="pl-1">
                        <v-radio-group v-model="filteOrderDataSet.radio_item" row hide-details>
                          <v-radio :label="items.name" v-for="items in filteOrderDataSet.radio" :key="items.id"
                            :value="items.id"></v-radio>
                        </v-radio-group>
                      </td>
                      <td class="pl-1">
                        <v-text-field class="text-center" style="width: 100px; text-align: center" type="number"
                          v-model="filteOrderDataSet.item_start" placeholder="จาก" outlined hide-details dense
                          :disabled="!filteOrderDataSet.radio_item" />
                      </td>
                      <td class="pl-1">
                        <span>ถึง</span>
                      </td>
                      <td class="pl-1">
                        <v-text-field class="text-center" type="number" style="width: 100px; text-align: center"
                          v-model="filteOrderDataSet.item_end" placeholder="จาก" outlined hide-details dense
                          :disabled="!filteOrderDataSet.radio_item" />
                      </td>
                      <td class="pl-1">
                        <span>ชิ้น</span>
                      </td>
                    </tr>
                  </table>

                  <v-data-table :headers="filteOrderDataSet.header" :items="filteOrderDataSet.dataset"
                    :items-per-page="9999999999" item-key="no" hide-default-footer
                    class="elevation-0 packhai-checkbox-select box-shadow" height="50vh"
                    v-model="filteOrderDataSet.selected" show-select fixed-header>
                    <template v-slot:item.outStock="{ item }">
                      <span class="red--text" v-if="item.outStock < 0">
                        {{ formatMoney(item.outStock) }}</span>
                    </template>
                  </v-data-table>
                </div>
                <div v-if="i.id == 1">
                  <v-data-table :headers="filteOrderDataSet.headerMapSKUList" :key="0"
                    :items="filteOrderDataSet.mapSKUList" hide-default-footer fixed-header
                    item-key="orderMasterPackageInfoID" class="elevation-0 packhai-border-table">
                  </v-data-table>
                </div>
                <div v-if="i.id == 2">
                  <v-data-table :headers="filteOrderDataSet.headerMapProductList" :key="0"
                    :items="filteOrderDataSet.mapProductList" hide-default-footer fixed-header
                    item-key="orderMasterPackageInfoID" class="elevation-0 packhai-border-table">
                  </v-data-table>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>

        <v-divider />
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              เลือกทั้งหมด {{ filteOrderDataSet.selected.length }} รายการ
            </v-col>
            <v-col cols="6" align="right">
              <v-btn color="primary" @click="btnSumitFilteOrder()"> ดำเนินการ </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ผูกสินค้า -->
    <v-dialog v-model="MappedProductsPopup.dialog" scrollable :max-width="MappedProductsPopup.MaxWidth" persistent
      :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
          <div class="v-list-item">
            <div class="pa-2">
              <span>
                <h5>ผูกสินค้า</h5>
              </span>
            </div>
            <div class="v-list-item__content pa-2"></div>
            <div>
              <v-btn v-if="MappedProductsDataSet.loading" icon @click="
                refreshWindow('MappedProductsPopup'),
                (MappedProductsPopup.dialog = false)
                "><v-icon color="danger">mdi-close</v-icon></v-btn>
              <v-btn v-else icon @click="MappedProductsPopup.dialog = false"><v-icon
                  color="danger">mdi-close</v-icon></v-btn>
            </div>
          </div>
        </v-card-title>

        <v-card flat class="ma-0 pa-0">
          <v-card-text class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="7">
                <h4 class="pa-2">สินค้าทั้งหมด</h4>
                <v-data-table :headers="MappedProductsDataSet.headerToMap"
                  :items="MappedProductsDataSet.productListToMapDataSet" :items-per-page="9999999999" item-key="name"
                  hide-default-footer class="elevation-0 packhai-checkbox-select box-shadow" height="45vh"
                  @click:row="rowClick" v-model="MappedProductsDataSet.selectedToMap" fixed-header>
                  <template v-slot:item.notTie="{ item, index }">
                    <a @click="btnMapDataSet(index, 1)">ไม่ผูกสินค้า </a>
                  </template>
                  <template v-slot:item.photoLink="{ item }">
                    <a v-if="item.photoLink != null && item.photoLink != ''" icon @click="OpenPhoto(item.photoLink)">
                      <img :src="item.photoLink" class="picsize-50 mt-1" />
                    </a>
                  </template>
                  <template v-slot:item.search="{ item, index }">
                    <a @click="btnSearchProductItem(item)">ค้นหา </a>
                  </template>
                </v-data-table>
                <h4 class="pa-1">รายการที่ไม่ต้องผูกสินค้า</h4>
                <v-data-table :headers="MappedProductsDataSet.headerNotMap"
                  :items="MappedProductsDataSet.productListNotMapDataSet" :items-per-page="9999999999"
                  hide-default-footer class="elevation-0 packhai-checkbox-select box-shadow" height="20vh"
                  v-model="MappedProductsDataSet.selectedNotMap" fixed-header>
                  <template v-slot:item.photoLink="{ item }">
                    <a v-if="item.photoLink != null && item.photoLink != ''" icon @click="OpenPhoto(item.photoLink)">
                      <img :src="item.photoLink" class="picsize-50 mt-1" />
                    </a>
                  </template>
                  <template v-slot:item.back="{ item, index }">
                    <a @click="btnMapDataSet(index, 2)">ย้อนกลับ </a>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="5">
                <h4>
                  สินค้าที่ต้องแพ็ก
                  <h4 class="cut-text">
                    ชื่อสินค้า :
                    <span v-if="MappedProductsDataSet.selectedToMap.length > 0">
                      {{ MappedProductsDataSet.selectedToMap[0].name }}</span>
                  </h4>
                </h4>
                <v-data-table :headers="MappedProductsDataSet.headerPruductPackhai"
                  :items="MappedProductsDataSet.productLisDataSet" :items-per-page="9999999999" hide-default-footer
                  class="elevation-0 packhai-checkbox-select box-shadow" height="68vh" fixed-header>
                  <template v-slot:item.photoLink="{ item }">
                    <a v-if="item.photoLink != null && item.photoLink != ''" icon @click="OpenPhoto(item.photoLink)">
                      <img :src="item.photoLink" class="picsize-50 mt-1" />
                    </a>
                  </template>
                  <template v-slot:item.delete="{ item, index }">
                    <a @click="btnAddMppDelete(index, item)">ลบ </a>
                  </template>
                  <template v-slot:item.quantity="{ item }">
                    <v-text-field ref="quantityInput" :value="item.quantity"
                      @change="(value) => (item.quantity = value)" autocomplete="off" placeholder="จำนวน" required
                      outlined type="number" hide-details @blur="quantityInput(item)"></v-text-field>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider />
          <v-card-actions>
            <v-row>
              <v-col cols="6">
                <template v-if="MappedProductsDataSet.loading == true">
                  <template>
                    <strong>ดำเนินการแล้ว
                      <span style="color: green"> {{ percentLoading }}%</span>
                    </strong>
                  </template>
                </template>
              </v-col>
              <v-col cols="6" align="right">
                <v-btn color="primary" :disabled="MappedProductsDataSet.loading" @click="btnSubmitTieMap()">
                  ดำเนินการ
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ExcelDialog" scrollable :max-width="approveDialogWidth" persistent>
      <v-card>
        <v-card-title>
          <span>ระบุเงื่อนไข</span>
          <v-spacer />
          <v-btn icon @click="ExcelDialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-checkbox label="Export ข้อมูลสินค้า" v-model="isNeedItemWhenExport" @change=" isFulfillSecondRow = isNeedItemWhenExport">
          </v-checkbox>
          <v-checkbox  :disabled="!isNeedItemWhenExport" label="ใส่ข้อมูลทุกแถวหากในออเดอร์นั้นมีสินค้ามากกว่า 1 รายการ" v-model="isFulfillSecondRow">
          </v-checkbox>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
              <v-col cols="12" style="display:flex; flex-direction: row-reverse;">
                <v-btn :elevation="1" color="primary" outlined class="px-2" @click="ExcelDialog=false,ExportExcel()">
                 
                  <span class="fn-14"><b> ดำเนินการ</b></span>
                </v-btn>
              </v-col>
            </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- เลือกสินค้า -->
    <v-dialog v-model="addStockPopup.dialog" scrollable :max-width="addStockPopup.MaxWidth" persistent
      :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
          <div class="v-list-item">
            <div class="pa-2">
              <v-select style="width: 150px" label="ค้นหาโดย" v-model="addStockPopup.SearchBy"
                :items="['SKU', 'ชื่อสินค้า', 'Barcode', 'รหัสสินค้า']" outlined dense hide-details></v-select>
            </div>
            <div class="v-list-item__content pa-2">
              <v-text-field ref="addStockPopup.refSearchStockModelPopup" :value="addStockPopup.searchStockModelPopup"
                autocomplete="off" class="pl-2" dense hide-details
                @change="(value) => (addStockPopup.searchStockModelPopup = value)"
                @keyup.enter="btnPopupsearchStock(addStockPopup.limits, 0)"></v-text-field>
            </div>
            <div class="pa-2">
              <v-btn :elevation="1" color="primary" class="pa-2" dark
                @click="btnPopupsearchStock(addStockPopup.limits, 0)">
                <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                ค้นหา
              </v-btn>
            </div>
            <div>
              <v-btn icon @click="addStockPopup.dialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
            </div>
          </div>
        </v-card-title>
        <v-divider />
        <v-row class="text-center" align="center" v-if="addStockPopup.selectProductList.length > 1">
          <v-col cols="12">
            <div style="background: rgb(235 235 235)">
              <b>คุณเลือกสินค้า {{ addStockPopup.selectProductList.length }} รายการ </b>
            </div>
          </v-col>
        </v-row>
        <v-card-text class="overflow-x-auto pt-1 pb-1 hidden-xs-only">
          <v-row></v-row>
          <v-data-table :headers="addStockPopup.header" :items="addStockPopup.dataset" :items-per-page="9999999999"
            hide-default-footer height="50vh" class="elevation-0 packhai-checkbox-select">
            <template v-slot:item.selectProduct="{ item, index }">
              <v-btn :outlined="!item.selectProduct" :x-small="true" :depressed="item.selectProduct" color="primary"
                @click="btnProductSelectCheckbox(item, index)">
                เลือก
              </v-btn>
            </template>
            <template v-slot:item.photoLink="{ item }">
              <a v-if="item.photoLink != null && item.photoLink != ''" icon @click="OpenPhoto(item.photoLink)">
                <img :src="item.photoLink" class="picsize-50 mt-1" />
              </a>
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:item.prop1="{ item }">
              <span class="fn-13">
                {{
                  (item.prop1Description != null && item.prop1Description != ""
                    ? item.prop1Description
                    : "") +
                  (item.prop2Description != null && item.prop2Description != ""
                    ? " , " + item.prop2Description
                    : "")
                }}
              </span>
            </template>
            <template v-slot:item.createdDatetime="{ item }">
              <span class="fn-13">
                {{ formatDate_thai(item.createdDatetime) }}
              </span>
            </template>
            <template v-slot:item.quantityLeft="{ item }">
              {{ formatMoney(item.quantityLeft) }}
            </template>
            <template v-slot:item.costPrice="{ item }">
              {{ formatMoney(item.costPrice) }}
            </template>
            <template v-slot:item.manufactureDateNow="{ item }">
              <span class="fn-13">
                {{ formatDate_thai(item.manufactureDateStart) }}
              </span>
            </template>
            <template v-slot:item.expirationDateNow="{ item }">
              <span class="fn-13">
                {{ formatDate_thai(item.expirationDateStart) }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
        <div v-if="addStockPopup.pageLength > 1 && addStockPopup.dataset.length > 0" class="text-center pa-1 ma-0">
          <v-pagination v-model="addStockPopup.page" :length="addStockPopup.pageLength" :total-visible="9"
            color="error"></v-pagination>
        </div>
        <v-divider />
        <div class="height-57 pa-2" align="right">
          <v-btn :elevation="1" color="primary" class="px-2" @click="btnAddStockPopup()">
            เพิ่มสินค้า
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <!-- รายงาน -->
    <v-dialog v-model="reportOrderPopup.dialog" scrollable :max-width="reportOrderPopup.MaxWidth" persistent
      :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
          <div class="v-list-item">
            <div class="pa-2">
              <span v-if="this.selectedTab == 10">
                <h5>รายการที่ต้องผูกสินค้า</h5>
              </span>
              <span v-else>
                <h5>รายการสินค้า</h5>
              </span>
            </div>
            <div class="v-list-item__content pa-2">
              <v-row>
                <v-col cols="6" />
                <v-col cols="6" class="text-right">
                  <v-btn :elevation="1" outlined color="success" class="pa-2">
                    <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
                    <vue-excel-xlsx :data="reportOrderDataSet.dataset" :columns="reportOrderDataSet.headerExcel"
                      :file-name="GetDate() + '_รายงานสินค้า'" :sheet-name="'reportOrderItemExcel'" class="mr-2">
                      Export to Excel
                    </vue-excel-xlsx>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div>
              <v-btn icon @click="reportOrderPopup.dialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
            </div>
          </div>
        </v-card-title>

        <v-card class="overflow-x-auto hidden-xs-only">
          <v-card-text class="pa-0 ma-0">
            <v-card flat>
              <v-card-text>
                <div>
                  <v-data-table :headers="reportOrderDataSet.header" :items="reportOrderDataSet.dataset"
                    :items-per-page="9999999999" item-key="no" hide-default-footer
                    class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                    <template v-slot:item.photoLink="{ item }">
                      <a v-if="item.photoLink != null && item.photoLink != ''" icon @click="OpenPhoto(item.photoLink)">
                        <img :src="item.photoLink" class="picsize-50 mt-1" />
                      </a>
                    </template>
                    <template v-slot:item.productName="{ item }">
                      {{ item.productName }}
                      <span v-if="item.prop1Description" style="color:grey;font-size: 12px;"><br>
                        {{ item.prop1Description + ((item.prop2Description)? ", "+item.prop2Description:"") }}
                      </span>
                    </template>
                  </v-data-table>
                </div>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>

        <v-divider />
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              ทั้งหมด {{ reportOrderDataSet.dataset.length }} รายการ
              <span>, จำนวน {{ countreportOrderDataSet }} ชิ้น</span>
            </v-col>
            <v-col cols="6" align="right"> </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- เปลี่ยนสถานะ -->
    <v-dialog v-model="changeStatusPopup.dialog" scrollable :max-width="changeStatusPopup.MaxWidth" persistent
      :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card class="ma-0">
        <v-card-title class="height-57 pa-0">
          <div class="v-list-item">
            <div class="pa-2">
              <span>
                <h5>เปลี่ยนสถานะ</h5>
              </span>
            </div>
            <div class="v-list-item__content pa-2">
              <v-row>
                <v-col cols="3">
                  <v-select v-model="changeStatusDataSet.changeStatus" :items="showManageChangeStatusList"
                    label="-- กรุณาเลือกสถานะ --" persistent-hint return-object dense hide-details flat outlined
                    item-text="Name" item-value="ID"></v-select></v-col>
                <v-col cols="9" class="text-right"> </v-col>
              </v-row>
            </div>
            <div>
              <v-btn v-if="changeStatusDataSet.loading" icon @click="
                refreshWindow('changeStatusPopup'), (changeStatusPopup.dialog = false)
                "><v-icon color="danger">mdi-close</v-icon></v-btn>
              <v-btn v-else icon @click="changeStatusPopup.dialog = false"><v-icon
                  color="danger">mdi-close</v-icon></v-btn>
            </div>
          </div>
        </v-card-title>

        <v-card class="overflow-x-auto hidden-xs-only">
          <v-card-text>
            <div style="color: red" v-if="
              changeStatusDataSet.changeStatus != null &&
              changeStatusDataSet.changeStatus.ID == 120
            ">
              <ul>
                <li>
                  การเปลี่ยนสถานะเป็น "{{ changeStatusDataSet.changeStatus.Name }}"
                  จะไม่มีการคืนจำนวนสต๊อกกลับเข้าไป
                </li>
                <li>
                  หากต้องการคืนสต๊อกจากการตีกลับให้ทำงานตีกลับที่ เมนู "นำเข้า/นำออก"
                </li>
              </ul>
            </div>

            <v-tabs class="mt-5" v-model="changeStatusPopup.tab">
              <v-tab v-for="i in changeStatusPopup.tab_list" :key="i.id" @click="changeStatusPopup.tab = i.id">
                {{ i.name }}
                <v-badge v-if="i.id == 1" color="error" inline>
                  <template v-slot:badge>
                    <span>{{ changeStatusDataSet.error }} </span>
                  </template>
                </v-badge>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="changeStatusPopup.tab" touchless>
              <v-tab-item v-for="i in changeStatusPopup.tab_list" :key="i.id">
                <div v-if="i.id == 0">
                  <v-data-table :headers="changeStatusDataSet.header" :items="changeStatusDataSet.dataset"
                    :items-per-page="9999999999" item-key="packageID" hide-default-footer
                    class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                    <template v-slot:item.referenceSourceLogo="{ item }">
                      <v-avatar size="25px" tile>
                        <v-img v-if="
                          item.referenceSourceLogo != null &&
                          item.referenceSourceLogo != ''
                        " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                      </v-avatar>
                    </template>
                    <template v-slot:item.expressCompanyLogo="{ item }">
                      <img v-if="
                        item.expressCompanyLogo != null && item.expressCompanyLogo != ''
                      " width="60px" :src="item.expressCompanyLogo" />
                    </template>
                    <template v-slot:item.processing="{ item }">
                      <div v-if="item.processing == 'success'">
                        <v-icon size="20" color="success">mdi-check-circle </v-icon>
                        <span>เปลี่ยนสถานะสำเร็จ </span>
                      </div>

                      <div v-else-if="item.processing == 'error'">
                        <v-icon size="25" color="danger">mdi-close</v-icon>
                        <span>{{ item.error_description }} </span>
                        <!-- <span 
                    >ไม่สำเร็จ
                  </span> -->
                      </div>
                    </template>
                  </v-data-table>
                </div>
                <div v-if="i.id == 1">
                  <v-data-table :headers="changeStatusDataSet.header" :items="changeStatusDataSet.datasetError"
                    :items-per-page="9999999999" item-key="packageID" hide-default-footer
                    class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                    <template v-slot:item.referenceSourceLogo="{ item }">
                      <v-avatar size="25px" tile>
                        <v-img v-if="
                          item.referenceSourceLogo != null &&
                          item.referenceSourceLogo != ''
                        " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                      </v-avatar>
                    </template>
                    <template v-slot:item.expressCompanyLogo="{ item }">
                      <img v-if="
                        item.expressCompanyLogo != null && item.expressCompanyLogo != ''
                      " width="60px" :src="item.expressCompanyLogo" />
                    </template>
                    <template v-slot:item.processing="{ item }">
                      <div v-if="item.processing == 'success'">
                        <v-icon size="20" color="success">mdi-check-circle </v-icon>
                        <span>เปลี่ยนสถานะสำเร็จ </span>
                      </div>

                      <div v-else-if="item.processing == 'error'">
                        <v-icon size="25" color="danger">mdi-close</v-icon>
                        <span>ไม่สำเร็จ </span>
                        <span>{{ item.error_description }} </span>
                      </div>
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>

        <v-divider />

        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <template v-if="changeStatusDataSet.loadingPercent == true">
                <strong>ดำเนินการแล้ว{{ percentLoading }}% </strong>
                <span> สำเร็จ {{ this.changeStatusDataSet.success }} รายการ , </span>
                <span> ไม่สำเร็จ {{ this.changeStatusDataSet.error }} รายการ </span>
              </template>
            </v-col>
            <v-col cols="6" align="right">
              <v-btn color="primary" :disabled="changeStatusDataSet.loading || changeStatusDataSet.changeStatus == null
                " @click="btnSubmitChamgeStatus()">
                ดำเนินการ
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ย้อนสถานะ -->
    <v-dialog v-model="backStatusPopup.dialog" scrollable :max-width="backStatusPopup.MaxWidth" persistent
      :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
          <div class="v-list-item">
            <div class="pa-2">
              <span>
                <h5>ย้อนสถานะ</h5>
              </span>
            </div>
            <div class="v-list-item__content pa-2">
              <v-row>
                <v-col cols="3">
                  <v-select v-model="backStatusDataSet.changeStatus" :items="showManageBackStatusList"
                    label="-- กรุณาเลือกสถานะ --" persistent-hint return-object dense hide-details flat outlined
                    item-text="Name" item-value="ID"></v-select></v-col>
                <v-col cols="9" class="text-right"> </v-col>
              </v-row>
            </div>
            <div>
              <v-btn v-if="backStatusDataSet.loading" icon @click="
                refreshWindow('backStatusPopup'), (backStatusPopup.dialog = false)
                "><v-icon color="danger">mdi-close</v-icon></v-btn>
              <v-btn v-else icon @click="backStatusPopup.dialog = false"><v-icon
                  color="danger">mdi-close</v-icon></v-btn>
            </div>
          </div>
        </v-card-title>

        <v-card class="overflow-x-auto hidden-xs-only">
          <v-card-text class="pa-0 ma-0">
            <v-card flat>
              <v-card-text>
                <v-row class="pl-4">
                  <v-col cols="12">
                    <v-tabs v-model="backStatusPopup.tab" hide-slider>
                      <v-tab v-for="i in backStatusPopup.tab_list" class="box-shadow" :key="i.id"
                        @click="backStatusPopup.tab = i.id">
                        {{ i.name }}
                        <v-badge v-if="i.id == 1" color="error" inline>
                          <template v-slot:badge>
                            <span>{{ backStatusDataSet.error }} </span>
                          </template>
                        </v-badge>
                      </v-tab>
                    </v-tabs>
                  </v-col>
                </v-row>
                <v-tabs-items v-model="backStatusPopup.tab" touchless>
                  <v-tab-item v-for="i in backStatusPopup.tab_list" :key="i.id">
                    <div v-if="i.id == 0">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="12">
                          <div>
                            <v-data-table :headers="backStatusDataSet.header" :items="backStatusDataSet.dataset"
                              :items-per-page="9999999999" item-key="packageID" hide-default-footer
                              class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                              <template v-slot:item.referenceSourceLogo="{ item }">
                                <v-avatar size="25px" tile>
                                  <v-img v-if="
                                    item.referenceSourceLogo != null &&
                                    item.referenceSourceLogo != ''
                                  " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                </v-avatar>
                              </template>
                              <template v-slot:item.expressCompanyLogo="{ item }">
                                <img v-if="
                                  item.expressCompanyLogo != null &&
                                  item.expressCompanyLogo != ''
                                " width="60px" :src="item.expressCompanyLogo" />
                              </template>
                              <template v-slot:item.processing="{ item }">
                                <div v-if="item.processing == 'success'">
                                  <v-icon size="20" color="success">mdi-check-circle
                                  </v-icon>
                                  <span>เปลี่ยนสถานะสำเร็จ </span>
                                </div>

                                <div v-else-if="item.processing == 'error'">
                                  <v-icon size="25" color="danger">mdi-close</v-icon>
                                  <!-- <span>{{ item.error_description }} </span> -->
                                  <span>ไม่สำเร็จ </span>
                                </div>
                              </template>
                            </v-data-table>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="i.id == 1">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="12">
                          <div>
                            <v-data-table :headers="backStatusDataSet.header" :items="backStatusDataSet.datasetError"
                              :items-per-page="9999999999" item-key="packageID" hide-default-footer
                              class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                              <template v-slot:item.referenceSourceLogo="{ item }">
                                <v-avatar size="25px" tile>
                                  <v-img v-if="
                                    item.referenceSourceLogo != null &&
                                    item.referenceSourceLogo != ''
                                  " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                </v-avatar>
                              </template>
                              <template v-slot:item.expressCompanyLogo="{ item }">
                                <img v-if="
                                  item.expressCompanyLogo != null &&
                                  item.expressCompanyLogo != ''
                                " width="60px" :src="item.expressCompanyLogo" />
                              </template>
                              <template v-slot:item.processing="{ item }">
                                <div v-if="item.processing == 'success'">
                                  <v-icon size="20" color="success">mdi-check-circle
                                  </v-icon>
                                  <span>เปลี่ยนสถานะสำเร็จ </span>
                                </div>

                                <div v-else-if="item.processing == 'error'">
                                  <v-icon size="25" color="danger">mdi-close</v-icon>
                                  <!-- <span>{{ item.error_description }} </span> -->
                                  <span>ไม่สำเร็จ </span>
                                </div>
                              </template>
                            </v-data-table>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>

        <v-divider />

        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <template v-if="backStatusDataSet.loadingPercent == true">
                <strong>กำลังโหลด{{ percentLoading }}%</strong>
                <span> สำเร็จ {{ this.backStatusDataSet.success }} รายการ , </span>
                <span> ไม่สำเร็จ {{ this.backStatusDataSet.error }} รายการ </span>
              </template>
            </v-col>
            <v-col cols="6" align="right">
              <v-btn color="primary" :disabled="backStatusDataSet.loading || backStatusDataSet.changeStatus == null
                " @click="btnSubmitBackStatus()">
                ดำเนินการ
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- updatatoMarketplace-->
    <v-dialog v-model="UpdateToMarketplacePopup.dialog" scrollable :max-width="UpdateToMarketplacePopup.MaxWidth"
      persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
          <div class="v-list-item">
            <div class="pa-2">
              <span>
                <h5 v-if="
                  UpdateToMarketplacePopup.marketplaceName == 'lazada' &&
                  UpdateToMarketplacePopup.marketplaceName != null
                ">
                  ดำเนินการ
                </h5>
                <h5 v-if="
                  UpdateToMarketplaceDataSet.tiktokType == 0 &&
                  UpdateToMarketplacePopup.marketplaceName == 'tiktok'
                ">
                  เลือกประเภท
                </h5>
                <h5 v-if="
                  UpdateToMarketplaceDataSet.tiktokType == 1 &&
                  UpdateToMarketplacePopup.marketplaceName == 'tiktok' &&
                  UpdateToMarketplacePopup.marketplaceName != null
                ">
                  ดำเนินการ
                </h5>
                <h5 v-if="
                  UpdateToMarketplaceDataSet.shopeeType == 0 &&
                  UpdateToMarketplacePopup.marketplaceName == 'shopee'
                ">
                  เลือกประเภท
                </h5>
                <h5 v-if="
                  UpdateToMarketplaceDataSet.shopeeType == 1 &&
                  UpdateToMarketplacePopup.marketplaceName == 'shopee'
                ">
                  เลือกที่อยู่
                </h5>
                <h5 v-if="
                  UpdateToMarketplaceDataSet.shopeeType == 2 &&
                  UpdateToMarketplacePopup.marketplaceName == 'shopee' &&
                  UpdateToMarketplacePopup.marketplaceName != null
                ">
                  ดำเนินการ
                </h5>
              </span>
            </div>
            <div class="v-list-item__content pa-2">
              <v-row>
                <v-col cols="3"> </v-col>
                <v-col cols="9" class="text-right"> </v-col>
              </v-row>
            </div>
            <div>
              <v-btn v-if="UpdateToMarketplaceDataSet.loading" icon @click="
                refreshWindow('UpdateToMarketplacePopup'),
                (UpdateToMarketplacePopup.dialog = false)
                "><v-icon color="danger">mdi-close</v-icon></v-btn>
              <v-btn v-else icon @click="UpdateToMarketplacePopup.dialog = false"><v-icon
                  color="danger">mdi-close</v-icon></v-btn>
            </div>
          </div>
        </v-card-title>

        <v-card class="overflow-x-auto hidden-xs-only">
          <v-card-text class="pa-0 ma-0">
            <v-card flat>
              <v-card-text>
                <v-row class="pl-4" v-if="
                  UpdateToMarketplacePopup.marketplaceName == 'lazada' ||
                  (UpdateToMarketplacePopup.marketplaceName == 'tiktok' &&
                    UpdateToMarketplaceDataSet.tiktokType == 1) ||
                  (UpdateToMarketplacePopup.marketplaceName == 'shopee' &&
                    UpdateToMarketplaceDataSet.shopeeType == 2)
                ">
                  <v-col cols="12">
                    <v-tabs v-model="UpdateToMarketplacePopup.tab" hide-slider>
                      <v-tab v-for="i in UpdateToMarketplacePopup.tab_list" class="box-shadow" :key="i.id"
                        @click="UpdateToMarketplacePopup.tab = i.id">
                        {{ i.name }}
                        <v-badge v-if="i.id == 1" color="error" inline>
                          <template v-slot:badge>
                            <span>{{ UpdateToMarketplaceDataSet.error }} </span>
                          </template>
                        </v-badge>
                      </v-tab>
                    </v-tabs>
                  </v-col>
                </v-row>
                <v-tabs-items v-model="UpdateToMarketplacePopup.tab" touchless>
                  <v-tab-item v-for="i in UpdateToMarketplacePopup.tab_list" :key="i.id">
                    <div v-if="i.id == 0">
                      <template v-if="
                        UpdateToMarketplacePopup.marketplaceName == 'lazada' &&
                        UpdateToMarketplacePopup.marketplaceName != null
                      ">
                        <v-row class="ma-0 pa-0">
                          <v-col cols="12">
                            <div>
                              <v-data-table :headers="UpdateToMarketplaceDataSet.header"
                                :items="UpdateToMarketplaceDataSet.dataset" :items-per-page="9999999999"
                                item-key="packageID" hide-default-footer
                                class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                <template v-slot:item.orderMasterID="{ item }">
                                  <a @click="ShowDetailOrder(item.orderMasterID, item.token)">{{
                                    FormatOrder(item.orderMasterID) }} </a>
                                </template>
                                <template v-slot:item.expressCompanyLogo="{ item }">
                                  <img v-if="
                                    item.expressCompanyLogo != null &&
                                    item.expressCompanyLogo != ''
                                  " width="60px" :src="item.expressCompanyLogo" />
                                </template>
                                <template v-slot:item.referenceSourceLogo="{ item }">
                                  <v-avatar size="25px" tile>
                                    <v-img v-if="
                                      item.referenceSourceLogo != null &&
                                      item.referenceSourceLogo != ''
                                    " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                  </v-avatar>
                                </template>
                                <template v-slot:item.processing="{ item }">
                                  <div v-if="item.processing == 'success'">
                                    <v-icon size="20" color="success">mdi-check-circle
                                    </v-icon>
                                    <span v-if="item.message != null && item.message != ''">{{ item.message }} </span>
                                    <span v-else>สำเร็จ </span>
                                  </div>

                                  <div v-else-if="item.processing == 'error'">
                                    <v-icon size="25" color="danger">mdi-close</v-icon>
                                    <span v-if="item.message != null && item.message != ''">{{ item.message }} </span>
                                    <span v-else>ทำรายการไม่สำเร็จ</span>
                                  </div>
                                </template>
                              </v-data-table>
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-if="
                        UpdateToMarketplacePopup.marketplaceName == 'tiktok' &&
                        UpdateToMarketplacePopup.marketplaceName != null
                      ">
                        <v-row class="ma-0 pa-0">
                          <v-col cols="12">
                            <div v-if="UpdateToMarketplaceDataSet.tiktokType == 0">
                              <v-row class="text-center" justify="center">
                                <!-- PICK_UP  -->
                                <v-col cols="4" @click="
                                    {
                                  {
                                    (UpdateToMarketplaceDataSet.tiktokType = 1),
                                      (UpdateToMarketplaceDataSet.handover_method = `PICK_UP`);
                                  }
                                }
                                  ">
                                  <v-card color="warning">
                                    <v-card-title class="textChip-center">
                                      <v-icon size="100" color="white">mdi-map-marker
                                      </v-icon>
                                    </v-card-title>
                                    <v-card-text class="color-white">
                                      <h3>นัดรับสินค้า</h3>
                                      <h3>เรียกให้ขนส่งมารับสินค้า</h3>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                                <!-- DROP_OFF  -->
                                <v-col cols="4" @click="
                                    {
                                  {
                                    (UpdateToMarketplaceDataSet.tiktokType = 1),
                                      (UpdateToMarketplaceDataSet.handover_method = `DROP_OFF`);
                                  }
                                }
                                  ">
                                  <v-card color="success">
                                    <v-card-title class="textChip-center">
                                      <v-icon size="100" color="white"> mdi-home </v-icon>
                                    </v-card-title>
                                    <v-card-text class="color-white">
                                      <h3>ส่งที่สาขา</h3>
                                      <h3>ไปส่งที่สาขาด้วยตนเอง</h3>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </div>

                            <div v-if="UpdateToMarketplaceDataSet.tiktokType == 1">
                              <v-data-table :headers="UpdateToMarketplaceDataSet.header"
                                :items="UpdateToMarketplaceDataSet.dataset" :items-per-page="9999999999"
                                item-key="packageID" hide-default-footer
                                class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                <template v-slot:item.orderMasterID="{ item }">
                                  <a @click="ShowDetailOrder(item.orderMasterID, item.token)">{{
                                    FormatOrder(item.orderMasterID) }} </a>
                                </template>
                                <template v-slot:item.expressCompanyLogo="{ item }">
                                  <img v-if="
                                    item.expressCompanyLogo != null &&
                                    item.expressCompanyLogo != ''
                                  " width="60px" :src="item.expressCompanyLogo" />
                                </template>
                                <template v-slot:item.referenceSourceLogo="{ item }">
                                  <v-avatar size="25px" tile>
                                    <v-img v-if="
                                      item.referenceSourceLogo != null &&
                                      item.referenceSourceLogo != ''
                                    " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                  </v-avatar>
                                </template>
                                <template v-slot:item.processing="{ item }">
                                  <div v-if="item.processing == 'success'">
                                    <v-icon size="20" color="success">mdi-check-circle
                                    </v-icon>
                                    <span v-if="item.message != null && item.message != ''">{{ item.message }} </span>
                                    <span v-else>สำเร็จ </span>
                                  </div>

                                  <div v-else-if="item.processing == 'error'">
                                    <v-icon size="25" color="danger">mdi-close</v-icon>
                                    <span v-if="item.message != null && item.message != ''">{{ item.message }} </span>
                                    <span v-else>ทำรายการไม่สำเร็จ</span>
                                  </div>
                                </template>
                              </v-data-table>
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-if="
                        UpdateToMarketplacePopup.marketplaceName == 'shopee' &&
                        UpdateToMarketplacePopup.marketplaceName != null
                      ">
                        <v-row class="ma-0 pa-0">
                          <v-col cols="12">
                            <div v-if="UpdateToMarketplaceDataSet.shopeeType == 0">
                              <v-row class="text-center" justify="center">
                                <!-- PICK_UP  -->
                                <v-col cols="4" @click="UpdateToMarketplaceDataSet.shopeeType = 1"
                                  v-if="UpdateToMarketplaceDataSet.pickup != null && UpdateToMarketplaceDataSet.pickup.length>0">
                                  <v-card color="warning">
                                    <v-card-title class="textChip-center">
                                      <v-icon size="100" color="white">mdi-map-marker
                                      </v-icon>
                                    </v-card-title>
                                    <v-card-text class="color-white">
                                      <h3>Pickup</h3>
                                      <h3>เรียกให้ขนส่งมารับสินค้า</h3>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                                <!-- DROP_OFF  -->
                                <v-col cols="4" @click="UpdateToMarketplaceDataSet.shopeeType = 2">
                                  <v-card color="success">
                                    <v-card-title class="textChip-center">
                                      <v-icon size="100" color="white"> mdi-home </v-icon>
                                    </v-card-title>
                                    <v-card-text class="color-white">
                                      <h3>Drop-off</h3>
                                      <h3>ไปส่งที่สาขาด้วยตนเอง</h3>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </div>
                            <div v-if="UpdateToMarketplaceDataSet.shopeeType == 1">
                              <v-card class="mb-3">
                                <v-card-text>
                                  <v-row class="text-center">
                                    <v-col cols="12">
                                      <v-radio-group label="เลือกสถานที่ขนส่ง" v-model="UpdateToMarketplaceDataSet.selectedAddress
                                        " @change="handleAddressChange()" style="max-height: 35vh; overflow-y: auto">
                                        <v-radio v-for="(
                                            items, index
                                          ) of UpdateToMarketplaceDataSet.pickup" :key="items.id" :value="index">
                                          <template v-slot:label>
                                            <v-col cols="12">
                                              <strong>{{
                                                items.address +
                                                " " +
                                                items.city +
                                                " " +
                                                items.district +
                                                " " +
                                                items.state +
                                                " " +
                                                items.zipcode
                                              }}</strong>
                                              <div v-if="items.address_flag.length > 0">
                                                <h4>
                                                  <v-chip color="success">
                                                    ค่าเริ่มต้น</v-chip>
                                                  <v-chip v-if="
                                                    items.address_flag ==
                                                    'default_address'
                                                  " color="success">
                                                    ค่าเริ่มต้น</v-chip>
                                                  <v-chip v-if="
                                                    items.address_flag ==
                                                    'pickup_address'
                                                  " color="warning">ที่อยู่รับสินค้า</v-chip>
                                                  <v-chip v-if="
                                                    items.address_flag ==
                                                    'return_address'
                                                  " color="question">ที่อยู่สำหรับคืนสินค้า</v-chip>
                                                </h4>
                                              </div>
                                            </v-col>
                                          </template>
                                        </v-radio>
                                      </v-radio-group>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>

                              <v-card>
                                <v-card-text>
                                  <v-row>
                                    <v-col cols="12">
                                      <v-icon size="25" class="pr-1">mdi-update</v-icon>
                                      <span>เวลานัดรับ</span>
                                    </v-col>
                                  </v-row>
                                  <v-row class="text-center mt-2">
                                    <v-col cols="12">
                                      <v-select v-model="UpdateToMarketplaceDataSet.pickupTimeID" :items="UpdateToMarketplaceDataSet.selectedpickupTime
                                        .time_slot_list
                                        " persistent-hint label="-- กรุณาเลือกเวลาขนส่ง --" dense hide-details flat
                                        outlined :item-text="(item) => item.time_text" item-value="pickup_time_id">
                                      </v-select>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </div>
                            <div v-if="UpdateToMarketplaceDataSet.shopeeType == 2">
                              <v-data-table :headers="UpdateToMarketplaceDataSet.header"
                                :items="UpdateToMarketplaceDataSet.dataset" :items-per-page="9999999999"
                                item-key="packageID" hide-default-footer
                                class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                                <template v-slot:item.orderMasterID="{ item }">
                                  <a @click="ShowDetailOrder(item.orderMasterID, item.token)">{{
                                    FormatOrder(item.orderMasterID) }} </a>
                                </template>
                                <template v-slot:item.expressCompanyLogo="{ item }">
                                  <img v-if="
                                    item.expressCompanyLogo != null &&
                                    item.expressCompanyLogo != ''
                                  " width="60px" :src="item.expressCompanyLogo" />
                                </template>
                                <template v-slot:item.referenceSourceLogo="{ item }">
                                  <v-avatar size="25px" tile>
                                    <v-img v-if="
                                      item.referenceSourceLogo != null &&
                                      item.referenceSourceLogo != ''
                                    " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                  </v-avatar>
                                </template>
                                <template v-slot:item.processing="{ item }">
                                  <div v-if="item.processing == 'success'">
                                    <v-icon size="20" color="success">mdi-check-circle
                                    </v-icon>
                                    <span v-if="item.message != null && item.message != ''">{{ item.message }} </span>
                                    <span v-else>สำเร็จ </span>
                                  </div>

                                  <div v-else-if="item.processing == 'error'">
                                    <v-icon size="25" color="danger">mdi-close</v-icon>
                                    <span v-if="item.message != null && item.message != ''">{{ item.message }} </span>
                                    <span v-else>ทำรายการไม่สำเร็จ</span>
                                  </div>
                                </template>
                              </v-data-table>
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                    </div>
                    <div v-if="i.id == 1">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="12">
                          <div>
                            <v-data-table :headers="UpdateToMarketplaceDataSet.header"
                              :items="UpdateToMarketplaceDataSet.datasetError" :items-per-page="9999999999"
                              item-key="packageID" hide-default-footer
                              class="elevation-0 packhai-checkbox-select box-shadow" height="50vh" fixed-header>
                              <template v-slot:item.orderMasterID="{ item }">
                                <a @click="ShowDetailOrder(item.orderMasterID, item.token)">{{
                                  FormatOrder(item.orderMasterID) }} </a>
                              </template>
                              <template v-slot:item.expressCompanyLogo="{ item }">
                                <img v-if="
                                  item.expressCompanyLogo != null &&
                                  item.expressCompanyLogo != ''
                                " width="60px" :src="item.expressCompanyLogo" />
                              </template>
                              <template v-slot:item.referenceSourceLogo="{ item }">
                                <v-avatar size="25px" tile>
                                  <v-img v-if="
                                    item.referenceSourceLogo != null &&
                                    item.referenceSourceLogo != ''
                                  " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                </v-avatar>
                              </template>
                              <template v-slot:item.processing="{ item }">
                                <div v-if="item.processing == 'success'">
                                  <v-icon size="20" color="success">mdi-check-circle
                                  </v-icon>
                                  <span v-if="item.message != null && item.message != ''">{{ item.message }} </span>
                                  <span v-else>สำเร็จ </span>
                                </div>

                                <div v-else-if="item.processing == 'error'">
                                  <v-icon size="25" color="danger">mdi-close</v-icon>
                                  <span v-if="item.message != null && item.message != ''">{{ item.message }} </span>
                                  <span v-else>ทำรายการไม่สำเร็จ</span>
                                </div>
                              </template>
                            </v-data-table>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col cols="6">
                    <template v-if="UpdateToMarketplaceDataSet.loadingPercent == true">
                      <strong>ดำเนินการแล้ว{{ percentLoading }}%</strong>
                      <span>
                        สำเร็จ {{ this.UpdateToMarketplaceDataSet.success }} รายการ ,
                      </span>
                      <span>
                        ไม่สำเร็จ {{ this.UpdateToMarketplaceDataSet.error }} รายการ
                      </span>
                    </template>
                  </v-col>
                  <v-col cols="6" align="right">
                    <div v-if="
                      UpdateToMarketplacePopup.marketplaceName == 'lazada' &&
                      UpdateToMarketplacePopup.marketplaceName != null
                    ">
                      <v-btn :disabled="UpdateToMarketplaceDataSet.loading" color="primary"
                        @click="btnSubmitToMarketPlace('lazada')">
                        ดำเนินการ
                      </v-btn>
                    </div>
                    <div v-if="
                      UpdateToMarketplacePopup.marketplaceName == 'tiktok' &&
                      UpdateToMarketplacePopup.marketplaceName != null
                    ">
                      <v-btn v-if="UpdateToMarketplaceDataSet.tiktokType == 1" color="success" class="mr-3" outlined
                        :disabled="UpdateToMarketplaceDataSet.loading" @click="
                          {
                          {
                            (UpdateToMarketplaceDataSet.tiktokType = null),
                              (UpdateToMarketplaceDataSet.handover_method = null),
                              (UpdateToMarketplaceDataSet.tiktokType = 0);
                          }
                        }
                          ">
                        ย้อนกลับ
                      </v-btn>
                      <v-btn v-if="UpdateToMarketplaceDataSet.tiktokType == 1" :disabled="UpdateToMarketplaceDataSet.loading ||
                        UpdateToMarketplaceDataSet.handover_method == null
                        " color="primary" @click="btnSubmitToMarketPlace('tiktok')">
                        ดำเนินการ
                      </v-btn>
                    </div>
                    <div v-if="
                      UpdateToMarketplacePopup.marketplaceName == 'shopee' &&
                      UpdateToMarketplacePopup.marketplaceName != null
                    ">
                      <v-btn v-if="
                        UpdateToMarketplaceDataSet.shopeeType == 2 ||
                        UpdateToMarketplaceDataSet.shopeeType == 1
                      " color="success" class="mr-3" outlined :disabled="UpdateToMarketplaceDataSet.loading" @click="
                          {
                        {
                          (UpdateToMarketplaceDataSet.pickupTimeID = null),
                            (UpdateToMarketplaceDataSet.selectedAddress = []),
                            (UpdateToMarketplaceDataSet.shopeeType = 0);
                        }
                      }
                        ">
                        ย้อนกลับ
                      </v-btn>
                      <v-btn v-if="UpdateToMarketplaceDataSet.shopeeType == 1" color="primary"
                        :disabled="UpdateToMarketplaceDataSet.pickupTimeID == null"
                        @click="UpdateToMarketplaceDataSet.shopeeType = 2">
                        ถัดไป
                      </v-btn>
                      <v-btn v-if="UpdateToMarketplaceDataSet.shopeeType == 2" color="primary"
                        :disabled="UpdateToMarketplaceDataSet.loading" @click="btnSubmitToMarketPlace('shopee')">
                        ดำเนินการ
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- ปริ้นใบปะหน้า -->
    <v-dialog v-model="printAwsPopup.dialog" scrollable :max-width="printAwsPopup.MaxWidth" persistent
      :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
          <div class="v-list-item">
            <div class="pa-2">
              <span>
                <h5>ปริ้นใบปะหน้า</h5>
              </span>
            </div>
            <div class="v-list-item__content pa-2">
              <v-row>
                <v-col cols="3"> </v-col>
                <v-col cols="9" class="text-right"> </v-col>
              </v-row>
            </div>
            <div>
              <v-btn v-if="printAwsDataSet.loading" icon
                @click="refreshWindow('printAwsPopup'), (printAwsPopup.dialog = false)"><v-icon
                  color="danger">mdi-close</v-icon></v-btn>
              <v-btn v-else icon @click="printAwsPopup.dialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
            </div>
          </div>
        </v-card-title>

        <v-card class="overflow-x-auto hidden-xs-only">
          <v-card-text class="pa-0 ma-0">
            <v-card flat>
              <v-card-text>
                <v-row class="pl-4">
                  <v-col cols="12">
                    <v-tabs v-model="printAwsPopup.tab" hide-slider>
                      <v-tab v-for="i in printAwsPopup.tab_list" class="box-shadow" :key="i.id"
                        @click="printAwsPopup.tab = i.id">
                        {{ i.name }}

                        <v-badge v-if="i.id == 1" color="error" inline>
                          <template v-slot:badge>
                            <span>{{ printAwsDataSet.error }} </span>
                          </template>
                        </v-badge>
                      </v-tab>
                    </v-tabs>
                  </v-col>
                </v-row>
                <v-tabs-items v-model="printAwsPopup.tab" touchless>
                  <v-tab-item v-for="i in printAwsPopup.tab_list" :key="i.id">
                    <div v-if="i.id == 0">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="12">
                          <div>
                            <v-data-table :headers="printAwsDataSet.header" :items="printAwsDataSet.dataset"
                              :items-per-page="9999999999" item-key="packageID" hide-default-footer
                              class="elevation-0 packhai-checkbox-select box-shadow" height="30vh" fixed-header>
                              <template v-slot:item.orderMasterID="{ item }">
                                <a @click="ShowDetailOrder(item.orderMasterID, item.token)">{{
                                  FormatOrder(item.orderMasterID) }} </a>
                              </template>
                              <template v-slot:item.expressCompanyLogo="{ item }">
                                <img v-if="
                                  item.expressCompanyLogo != null &&
                                  item.expressCompanyLogo != ''
                                " width="60px" :src="item.expressCompanyLogo" />
                              </template>
                              <template v-slot:item.referenceSourceLogo="{ item }">
                                <v-avatar size="25px" tile>
                                  <v-img v-if="
                                    item.referenceSourceLogo != null &&
                                    item.referenceSourceLogo != ''
                                  " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                </v-avatar>
                              </template>
                              <template v-slot:item.processing="{ item }">
                                <div v-if="item.processing == 'success'">
                                  <v-icon size="20" color="success">mdi-check-circle
                                  </v-icon>
                                  <span>จัดเตรียมใบปะหน้าสำเร็จ </span>
                                </div>

                                <div v-else-if="item.processing == 'error'">
                                  <v-icon size="25" color="danger">mdi-close</v-icon>
                                  <span>ไม่สำเร็จ </span>
                                  <span>{{ item.error_description }} </span>
                                </div>
                              </template>
                            </v-data-table>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="i.id == 1">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="12">
                          <div>
                            <v-data-table :headers="printAwsDataSet.header" :items="printAwsDataSet.datasetError"
                              :items-per-page="9999999999" item-key="packageID" hide-default-footer
                              class="elevation-0 packhai-checkbox-select box-shadow" height="30vh" fixed-header>
                              <template v-slot:item.orderMasterID="{ item }">
                                <a @click="ShowDetailOrder(item.orderMasterID, item.token)">{{
                                  FormatOrder(item.orderMasterID) }} </a>
                              </template>
                              <template v-slot:item.expressCompanyLogo="{ item }">
                                <img v-if="
                                  item.expressCompanyLogo != null &&
                                  item.expressCompanyLogo != ''
                                " width="60px" :src="item.expressCompanyLogo" />
                              </template>
                              <template v-slot:item.referenceSourceLogo="{ item }">
                                <v-avatar size="25px" tile>
                                  <v-img v-if="
                                    item.referenceSourceLogo != null &&
                                    item.referenceSourceLogo != ''
                                  " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                </v-avatar>
                              </template>
                              <template v-slot:item.processing="{ item }">
                                <div v-if="item.processing == 'success'">
                                  <v-icon size="20" color="success">mdi-check-circle
                                  </v-icon>
                                  <span>จัดเตรียมใบปะหน้าสำเร็จ </span>
                                </div>

                                <div v-else-if="item.processing == 'error'">
                                  <v-icon size="25" color="danger">mdi-close</v-icon>
                                  <span>ไม่สำเร็จ </span>
                                  <span>{{ item.error_description }} </span>
                                </div>
                              </template>
                            </v-data-table>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>

        <v-divider />

        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <template v-if="printAwsDataSet.loadingPercent == true">
                <strong>กำลังโหลด {{ percentLoading }}%, </strong>
                <span> สำเร็จ {{ this.printAwsDataSet.success }} รายการ, </span>
                <span> ไม่สำเร็จ {{ this.printAwsDataSet.error }} รายการ </span>
              </template>
            </v-col>
            <v-col cols="6" align="right">
              <table style="width:100%;">
                <tr>
                  <td>
                  </td>
                  <td style="width:180px" class="text-right">
                    <v-checkbox label="โชว์สินค้าในใบปะหน้า"
                      v-if="printAwsDataSet.dataset != null && printAwsDataSet.dataset.length > 0 && printAwsDataSet.dataset[0].referenceSourceID != 3"
                      v-model="isNeedItemWhenPrint"></v-checkbox>
                  </td>
                  <td style="width:110px" class="text-right">
                    <v-btn color="primary" :disabled="printAwsDataSet.loading" @click="btnSubmitAwb()">
                      ดำเนินการ
                    </v-btn>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- จองสต็อก -->
    <v-dialog v-model="allocateStockPopup.dialog" scrollable :max-width="allocateStockPopup.MaxWidth" persistent
      :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
          <div class="v-list-item">
            <div class="pa-2">
              <span>
                <h5>จองสต๊อก</h5>
              </span>
            </div>
            <div class="v-list-item__content pa-2">
              <v-row>
                <v-col cols="4">
                  <v-select v-model="allocateStockDataSet.Select" :items="allocateStockDataSet.ListSelect"
                    label="-- กรุณาเลือกเงื่อนไขการจองสต๊อก --" persistent-hint dense hide-details flat outlined
                    item-text="name" item-value="value"></v-select>
                </v-col>
                <v-col cols="8" class="text-right"> </v-col>
              </v-row>
            </div>
            <div>
              <v-btn v-if="allocateStockDataSet.loading" icon @click="
                refreshWindow('allocateStockPopup'), (allocateStockPopup.dialog = false)
                "><v-icon color="danger">mdi-close</v-icon></v-btn>
              <v-btn v-else icon @click="allocateStockPopup.dialog = false"><v-icon
                  color="danger">mdi-close</v-icon></v-btn>
            </div>
          </div>
        </v-card-title>

        <v-card class="overflow-x-auto hidden-xs-only">
          <v-card-text class="pa-0 ma-0">
            <v-card flat>
              <v-card-text>
                <v-row class="pl-4 mt-2">
                  <v-col cols="12">
                    <v-tabs v-model="allocateStockPopup.tab">
                      <v-tab v-for="i in allocateStockPopup.tab_list" :key="i.id"
                        @click="allocateStockPopup.tab = i.id">
                        {{ i.name }}
                        <v-badge v-if="i.id == 1" color="error" inline>
                          <template v-slot:badge>
                            <span>{{ allocateStockDataSet.error }} </span>
                          </template>
                        </v-badge>
                      </v-tab>
                    </v-tabs>
                  </v-col>
                </v-row>

                <v-tabs-items v-model="allocateStockPopup.tab" touchless>
                  <v-tab-item v-for="i in allocateStockPopup.tab_list" :key="i.id">
                    <div v-if="i.id == 0">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="12">
                          <div>
                            <v-data-table :headers="allocateStockDataSet.header" :items="allocateStockDataSet.dataset"
                              :items-per-page="9999999999" item-key="packageID" hide-default-footer
                              class="elevation-1 packhai-border-table" fixed-header>
                              <template v-slot:item.orderMasterID="{ item }">
                                <a @click="ShowDetailOrder(item.orderMasterID, item.token)">{{
                                  FormatOrder(item.orderMasterID) }} </a>
                              </template>
                              <template v-slot:item.referenceSourceLogo="{ item }">
                                <v-avatar size="25px" tile>
                                  <v-img v-if="
                                    item.referenceSourceLogo != null &&
                                    item.referenceSourceLogo != ''
                                  " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                </v-avatar>
                              </template>
                              <template v-slot:item.expressCompanyLogo="{ item }">
                                <img v-if="
                                  item.expressCompanyLogo != null &&
                                  item.expressCompanyLogo != ''
                                " width="60px" :src="item.expressCompanyLogo" />
                              </template>
                              <template v-slot:item.processing="{ item }">
                                <div v-if="item.processing == 'success'">
                                  <v-icon size="20" color="success">mdi-check-circle
                                  </v-icon>
                                  <span>เปลี่ยนสถานะสำเร็จ </span>
                                </div>

                                <div v-else-if="item.processing == 'error'">
                                  <v-icon size="25" color="danger">mdi-close</v-icon>
                                  <span>ไม่สำเร็จ </span>
                                  <span>{{ item.error_description }} </span>
                                </div>
                              </template>
                            </v-data-table>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-if="i.id == 1">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="12">
                          <div>
                            <v-data-table :headers="allocateStockDataSet.header"
                              :items="allocateStockDataSet.datasetError" :items-per-page="9999999999"
                              item-key="packageID" hide-default-footer
                              class="elevation-0 packhai-checkbox-select box-shadow" height="30vh" fixed-header>
                              <template v-slot:item.orderMasterID="{ item }">
                                <a @click="ShowDetailOrder(item.orderMasterID, item.token)">{{
                                  FormatOrder(item.orderMasterID) }} </a>
                              </template>
                              <template v-slot:item.referenceSourceLogo="{ item }">
                                <v-avatar size="25px" tile>
                                  <v-img v-if="
                                    item.referenceSourceLogo != null &&
                                    item.referenceSourceLogo != ''
                                  " style="border-radius: 10px" :src="item.referenceSourceLogo" />
                                </v-avatar>
                              </template>
                              <template v-slot:item.expressCompanyLogo="{ item }">
                                <img v-if="
                                  item.expressCompanyLogo != null &&
                                  item.expressCompanyLogo != ''
                                " width="60px" :src="item.expressCompanyLogo" />
                              </template>
                              <template v-slot:item.processing="{ item }">
                                <div v-if="item.processing == 'success'">
                                  <v-icon size="20" color="success">mdi-check-circle
                                  </v-icon>
                                  <span>เปลี่ยนสถานะสำเร็จ </span>
                                </div>

                                <div v-else-if="item.processing == 'error'">
                                  <v-icon size="25" color="danger">mdi-close</v-icon>
                                  <span>ไม่สำเร็จ </span>
                                  <span>{{ item.error_description }} </span>
                                </div>
                              </template>
                            </v-data-table>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>

        <v-divider />

        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <template v-if="allocateStockDataSet.loadingPercent">
                <strong>ดำเนินการแล้ว {{ percentLoading }}%</strong>
              </template>
              <span> สำเร็จ {{ this.allocateStockDataSet.success }} รายการ , </span>
              <span> ไม่สำเร็จ {{ this.allocateStockDataSet.error }} รายการ </span>
            </v-col>

            <v-col cols="6" align="right">
              <v-btn color="primary" :disabled="allocateStockDataSet.loading == true ||
                allocateStockDataSet.Select == null
                " @click="btnSubmitAllocateStock()">
                ดำเนินการ
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { utils, writeFileXLSX } from 'xlsx';
import Loading from "@/website/components/Loading";
import {
  shopService_dotnet,
  orderService_dotnet,
  branchService_dotnet,
  stockService_dotnet,
  ecomService_dotnet,
  logisticService_dotnet,
} from "@/website/global";
import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
import {
  FormatOrder,
  DateNow,
  formatMoney,
  formatMoneyIsDecimal,
  Trim_value,
  Trim_rules,
  formatDatetime,
  length60,
  ChangeOrderId,
  GenPA,
  GetDate,
  UpdateViewTypePage,
  GetViewTypePage,
} from "@/website/global_function";
import Photo from "@/website/components/photo";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { forever, log } from "async";

export default {
  components: {
    Photo,
    Loading,
  },
  data: () => ({
    header_token: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("authorization_token"),
    },
    set_language: "th",
    staffBranchId: 0,
    staffShopID: 0,
    drawer: "",
    mini: false,
    selectedTab: 10,
    isNeedItemWhenPrint: false,
    loadingExcel:0,
    ExcelDialog:false,
    // แสดงภาพ
    BranchID: parseInt(localStorage.getItem("Branch_BranchID")),
    ShowPhoto: false,
    ShowPhotoLink: "",
    percentLoadingText: "",
    percentLoading: 0,
    latestLimitType: 1,
    page_loading: true,
    loading: false,
    sortDialog: false,
    advanceSearchDialog:false,
    menuDueDeliveryFrom:false,
    DueDeliveryDateFromText:null,
    DueDeliveryDateFromSelected:null,

    menuDueDeliveryTo:false,
    DueDeliveryDateToText:null,
    DueDeliveryDateToSelected:null,
    menuRPDFrom:false,
    menuRPDTo:false,
    ReadyPackDateToText:null,
    ReadyPackDateToSelected:null,
    ReadyPackDateFromText:null,
    ReadyPackDateFromSelected:null,
    progressBarActive:false,
    menuCFDateFrom:false,
    menuCFDateTo:false,
    CFDateFromText:null,
    CFDateFromSelected:null,
    CFDateToText:null,
    CFDateToSelected:null,
    tab: 0,
    dataTable: {
      header: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ออเดอร์",
          align: "center",
          sortable: false,
          value: "id",
          width: "110px",
        },
        {
          text: "PackageID",
          align: "center",
          sortable: false,
          value: "packageIDText",
          width: "110px",
        },
        {
          text: "วันที่สร้าง",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "160px",
        },

        {
          text: "ร้านค้า",
          align: "center",
          sortable: false,
          value: "shopName",
          width: "90px",
        },
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "90px",
        },
        {
          text: "จำนวนชิ้น",
          align: "center",
          sortable: false,
          value: "productCount",
          width: "90px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "channelName",
          width: "90px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyName",
          width: "90px",
        },

        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "110px",
        },
        {
          text: "นัดรับ",
          align: "center",
          sortable: false,
          value: "isInit",
          width: "50px",
        },
        {
          text: "ใบแพ็ค",
          align: "center",
          sortable: false,
          value: "isPrintPackingList",
          width: "70px",
        },
        {
          text: "ใบปะหน้า",
          align: "center",
          sortable: false,
          value: "isPrintAWB",
          width: "70px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "110px",
        },
        {
          text: "น้ำหนัก",
          align: "center",
          sortable: false,
          value: "weight",
          width: "90px",
        },
        {
          text: "ขนาด",
          align: "center",
          sortable: false,
          value: "boxSize",
          width: "100px",
        },
        {
          text: "กล่อง",
          align: "center",
          sortable: false,
          value: "boxName",
          width: "100px",
        },
        {
          text: "กำลังแพ็ค",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "แพ็คเสร็จโดย",
          align: "center",
          sortable: false,
          value: "packedByStaffBranchName",
          width: "90px",
        },
        {
          text: "วันที่ยืนยันออเดอร์",
          align: "center",
          sortable: false,
          value: "confirmedOrderDate",
          width: "160px",
        },
        {
          text: "วันที่พร้อมแพ็ก",
          align: "center",
          sortable: false,
          value: "readyToPackDate",
          width: "160px",
        },
        {
          text: "วันที่ต้องจัดส่ง",
          align: "center",
          sortable: false,
          value: "dueDeliveryDate",
          width: "160px",
        },
        {
          text: "เริ่มแพ็ก",
          align: "center",
          sortable: false,
          value: "startPackingDate",
          width: "160px",
        },
        {
          text: "แพ็กเสร็จ",
          align: "center",
          sortable: false,
          value: "endPackingDate",
          width: "160px",
        },
        {
          text: "ลูกค้า",
          align: "left",
          sortable: false,
          value: "receiverName",
          width: "200px",
        },
      ],
      headers: [],
      headerExcel: [
        { label: "ออเดอร์", field: "id", width: "10" },
        { label: "วันที่สร้าง", field: "createdDatetime", width: "10" },
        { label: "วันที่พร้อมแพ็ก", field: "readyToPackDate", width: "10" },
        { label: "ร้านค้า", field: "shopName", width: "10" },
        { label: "ช่องทาง", field: "channelName", width: "10" },
        { label: "ขนส่ง", field: "expressCompanyName", width: "10" },
        { label: "เลขอ้างอิง", field: "referenceNumber", width: "10" },
        { label: "TrackingNo", field: "trackingNo", width: "10" },
        { label: "ใบแพ็ค", field: "isPrintPackingList", width: "10" },
        { label: "ใบปะหน้า", field: "isPrintAWB", width: "10" },
        { label: "นัดรับ", field: "isInit", width: "10" },
        { label: "น้ำหนัก", field: "weight", width: "10" },
        { label: "ขนาด", field: "boxSize", width: "10" },
        { label: "กล่อง", field: "boxName", width: "10" },
        { label: "แพ็คโดย", field: "packedByStaffBranchName", width: "10" },
        { label: "เริ่มแพ็ค", field: "เริ่มแพ็ค", width: "10" },
        { label: "แพ็คเสร็จ", field: "แพ็คเสร็จ", width: "10" },
        { label: "ลูกค้า", field: "receiverName", width: "10" },
      ],
      removeColumn: [
        {
          ID: 10,
          remove: [
            "วันที่พร้อมแพ็ก",
            "จำนวน SKU",
            "จำนวนชิ้น",
            "นัดรับ",
            "ใบปะหน้า",
            "ใบแพ็ค",
            "น้ำหนัก",
            "ขนาด",
            "กล่อง",
            "กำลังแพ็ค",
            "แพ็คเสร็จโดย",
            "เริ่มแพ็ค",
            "แพ็คเสร็จ",
          ],
        },
        {
          ID: 20,
          remove: [
            "นัดรับ",
            "วันที่พร้อมแพ็ก",
            "ใบปะหน้า",
            "ใบแพ็ค",
            "น้ำหนัก",
            "ขนาด",
            "กล่อง",
            "กำลังแพ็ค",
            "แพ็คเสร็จโดย",
            "เริ่มแพ็ค",
            "แพ็คเสร็จ",
          ],
        },
        {
          ID: 30,
          remove: [
            "นัดรับ",
            "วันที่พร้อมแพ็ก",
            "ใบปะหน้า",
            "ใบแพ็ค",
            "น้ำหนัก",
            "ขนาด",
            "กล่อง",
            "กำลังแพ็ค",
            "แพ็คเสร็จโดย",
            "เริ่มแพ็ค",
            "แพ็คเสร็จ",
          ],
        },
        {
          ID: 40,
          remove: [
            "น้ำหนัก",
            "ขนาด",
            "กล่อง",
            "กำลังแพ็ค",
            "แพ็คเสร็จโดย",
            "เริ่มแพ็ค",
            "แพ็คเสร็จ",
          ],
        },
      ],

      itemsExcel: [],
      selected: [],
    },

    // ค้นหา
    search: {
      channelSourceList: [{ channelID: 0, channelName: "--ทุกช่องทาง--" }],
      channelSource: { channelID: 0, channelName: "--ทุกช่องทาง--" },

      select_per_page: 10,
      select_per: [
        { value: 5, name: "5 คำสั่งซื้อ" },
        { value: 10, name: "10 คำสั่งซื้อ" },
        { value: 25, name: "25 คำสั่งซื้อ" },
        { value: 50, name: "50 คำสั่งซื้อ" },
        { value: 100, name: "100 คำสั่งซื้อ" },
        { value: 500, name: "500 คำสั่งซื้อ" },
        { value: 999999, name: "ทั้งหมด" },
      ],

      select_exprees: {
        expressCompanyID: null,
        expressCompanyName: " -- ทั้งหมด --",
      },
      exprees: [{ expressCompanyID: null, expressCompanyName: " -- ทั้งหมด --" }],

      select_topic: { id: 1, name: "เลขออเดอร์", value: "orderId" },
      select_header: [
        { id: 0, name: "เลขออเดอร์", value: "orderId" },
        { id: 1, name: "เลขอ้างอิง", value: "referenceNumber" },
        { id: 2, name: "ชื่อผู้รับ", value: "receiverName" },
      ],
      keyword: {},
      text_topic: "",

      shop: { shopID: null, shopName: "--ทั้งหมด--" },
      shopList: [{ shopID: null, shopName: "--ทั้งหมด--" }],
      shopDefault: { shopID: null, shopName: "--ทั้งหมด--" },
      shopListDefault: [{ shopID: null, shopName: "--ทั้งหมด--" }],

      groupPrint: { id: null, name: " -- ทั้งหมด --" },
      groupPrintList: [{ id: null, name: " -- ทั้งหมด --" }],
    },

    page: 1,
    offsets: 0,
    limits: 10,
    pageLength: 1,
    total: 0,
    packingType: [],
    warehouseID_selected: [],
    warehouseID: null,

    filteOrderDataSet: {
      selected: [],
      filteOrderList: [],
      dataset: [],
      productList: [],
      mapProductList: [],
      mapSKUList: [],
      tab_list: [
        { id: 0, name: "กรองสินค้า" },
        { id: 1, name: "จำนวน SKU ในออเดอร์" },
        { id: 2, name: "จำนวน ชิ้น ในออเดอร์" },
      ],
      tab: 0,
      header: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "ร้านค้า",
          align: "left",
          sortable: false,
          value: "shopName",
          width: "100px",
        },
        {
          text: "ชื่อ",
          align: "left",
          sortable: false,
          value: "productName",
          width: "250px",
        },
        {
          text: "Barcode",
          align: "center",
          sortable: false,
          value: "barcode",
          width: "75px",
        },
        {
          text: "จำนวนออเดอร์ ",
          align: "center",
          sortable: false,
          value: "orderCount",
          width: "85px",
        },
        {
          text: "จำนวนต้องแพ็ค ",
          align: "center",
          sortable: false,
          value: "quantityToPack",
          width: "85px",
        },
        {
          text: "สต๊อกที่มี",
          align: "center",
          sortable: false,
          value: "currentQuantity",
          width: "85px",
        },
        {
          text: "ขาดสต๊อก",
          align: "center",
          sortable: false,
          value: "outStock",
          width: "85px",
        },
        {
          text: "SKU",
          align: "center",
          sortable: false,
          value: "sku",
          width: "90px",
        },
        {
          text: "รหัส CF",
          align: "center",
          sortable: false,
          value: "cfCode",
          width: "90px",
        },
        {
          text: "ชั้นวาง",
          align: "center",
          sortable: false,
          value: "rackNo",
          width: "90px",
        },
      ],
      headerExcel: [
        { label: "No", field: "no", width: "25" },
        { label: "ร้านค้า", field: "shopName", width: "25" },
        { label: "ชื่อ", field: "productName", width: "25" },
        { label: "Barcode", field: "barcode", width: "10" },
        { label: "จำนวนออเดอร์", field: "orderCount", width: "10" },
        { label: "จำนวนต้องแพ็ค", field: "quantityToPack", width: "10" },
        { label: "สต๊อกที่มี", field: "currentQuantity", width: "10" },
        { label: "ขาดสต๊อก", field: "outStockShow", width: "10" },
        { label: "SKU", field: "sku", width: "10" },
        { label: "รหัส CF", field: "cfCode", width: "10" },
        { label: "ชั้นวาง", field: "rackNo", width: "10" },
      ],
      headerMapProductList: [
        {
          text: "จำนวน ชิ้น",
          align: "center",
          sortable: false,
          value: "itemCount",
          width: "70px",
        },
        {
          text: "จำนวน ออเดอร์",
          align: "right",
          sortable: false,
          value: "orderCount",
          width: "100px",
        },
        {
          text: "ปริ้นใบหยิบแล้ว",
          align: "right",
          sortable: false,
          value: "PrintY",
          width: "100px",
        },
        {
          text: "ยังไม่ปริ้น",
          align: "right",
          sortable: false,
          value: "PrintN",
          width: "100px",
        },
      ],
      headerMapSKUList: [
        {
          text: "จำนวน SKU",
          align: "center",
          sortable: false,
          value: "skuCount",
          width: "70px",
        },
        {
          text: "จำนวน ออเดอร์",
          align: "right",
          sortable: false,
          value: "orderCount",
          width: "100px",
        },
        {
          text: "ปริ้นใบหยิบแล้ว",
          align: "right",
          sortable: false,
          value: "PrintY",
          width: "100px",
        },
        {
          text: "ยังไม่ปริ้น",
          align: "right",
          sortable: false,
          value: "PrintN",
          width: "100px",
        },
      ],
      radio: [
        { id: 0, name: "ทั้งหมด" },
        { id: 1, name: "จาก" },
      ],
      radio_sku: 0,
      radio_item: 0,
      sku_start: 1,
      sku_end: 1,
      item_start: 1,
      item_end: 1,
    },
    orderbyText: '',
    sortDialogMaxWidth: "500px",
    advanceDialogMaxWidth:"700px",
    filterOrderPopup: {
      dialog: false,
      MaxWidth: "80%",
      MaxHeight: 70,
    },
    MappedProductsPopup: {
      dialog: false,
      MaxWidth: "90%",
      MaxHeight: 80,
    },
    MappedProductsDataSet: {
      loading: false,
      selectedToMap: [],
      selectedNotMap: [],
      productListToMapDataSet: [],
      productListNotMapDataSet: [],
      productLisDataSet: [],
      headerToMap: [
        {
          text: "ค้นหา",
          align: "center",
          sortable: false,
          value: "search",
          width: "75px",
        },
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "รูป",
          align: "center",
          sortable: false,
          value: "photoLink",
          width: "70px",
        },

        {
          text: "ชื่อ",
          align: "left",
          sortable: false,
          value: "name",
          width: "250px",
        },
        {
          text: "ไม่ผูกสินค้า ",
          align: "center",
          sortable: false,
          value: "notTie",
          width: "85px",
        },
        {
          text: "รหัส CF ",
          align: "center",
          sortable: false,
          value: "cfCode",
          width: "85px",
        },
        {
          text: "SKU",
          align: "center",
          sortable: false,
          value: "sku",
          width: "85px",
        },
        {
          text: "จำนวน",
          align: "center",
          sortable: false,
          value: "quantity",
          width: "85px",
        },
      ],
      headerNotMap: [
        {
          text: "ย้อนกลับ",
          align: "center",
          sortable: false,
          value: "back",
          width: "75px",
        },
        {
          text: "รูป",
          align: "center",
          sortable: false,
          value: "photoLink",
          width: "70px",
        },
        {
          text: "รหัส CF ",
          align: "center",
          sortable: false,
          value: "cfCode",
          width: "85px",
        },
        {
          text: "สินค้า",
          align: "left",
          sortable: false,
          value: "name",
          width: "250px",
        },
        {
          text: "SKU ",
          align: "center",
          sortable: false,
          value: "sku",
          width: "85px",
        },
        {
          text: "จำนวน",
          align: "center",
          sortable: false,
          value: "quantity",
          width: "85px",
        },
      ],
      headerPruductPackhai: [
        {
          text: "ลบ",
          align: "center",
          sortable: false,
          value: "delete",
          width: "70px",
        },
        {
          text: "รูป ",
          align: "center",
          sortable: false,
          value: "photoLink",
          width: "75px",
        },
        {
          text: "ชื่อสินค้า",
          align: "left",
          sortable: false,
          value: "name",
          width: "150px",
        },
        {
          text: "SKU ",
          align: "center",
          sortable: false,
          value: "sku",
          width: "85px",
        },
        {
          text: "จำนวนที่ขาย",
          align: "center",
          sortable: false,
          value: "quantity",
          width: "120px",
        },
        {
          text: "จำนวนคงเหลือ",
          align: "center",
          sortable: false,
          value: "quantityAvailable",
          width: "85px",
        },
      ],
    },
    isFulfillSecondRow:true,
    isNeedItemWhenExport:true,
    sortAbleItem: [{ id: "op.SKUCount", name: 'จำนวน SKU' }, { id: "op.ProductCount", name: 'จำนวน ชิ้น' }, { id: "op.ReadyToPackDate", name: 'วันที่พร้อมแพ็ก' }, { id: "o.ConfirmedOrderDate", name: 'วันที่ยืนยันออเดอร์' }, { id: "o.CreatedDatetime", name: 'วันที่สร้างออเดอร์' }, { id: "op.DueDeliveryDate", name: 'วันที่ต้องจัดส่ง' }],
    sortType: [{ id: 'asc', name: 'น้อยไปมาก' }, { id: 'desc', name: 'มากไปน้อย' }],
    sortTypeSelected1: 'asc',
    sortTypeSelected2: 'asc',
    sortTypeSelected3: 'asc',
    sortTypeSelected4: 'asc',
    sortTypeSelected5: 'asc',
    sortTypeSelected6: 'asc',
    sortAbleSelected1: null,
    sortAbleSelected2: null,
    sortAbleSelected3: null,
    sortAbleSelected4: null,
    sortAbleSelected5: null,
    sortAbleSelected6: null,

    addStockPopup: {
      MaxWidth: "75 %",
      MaxHeight: 80,
      dialog: false,
      shopID: null,
      popupsearch: null,
      page: 1,
      pageTemp: 1,
      offsets: 0,
      limits: 10,
      pageLength: 1,
      SearchBy: "SKU",
      searchStockModelPopup: null,

      header: [
        {
          text: "เลือก",
          align: "center",
          sortable: false,
          value: "selectProduct",
          width: "50px",
        },
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "60px",
        },
        {
          text: "รูป",
          align: "center",
          sortable: false,
          value: "photoLink",
          width: "60px",
        },
        {
          text: "ชื่อ",
          align: "left",
          sortable: false,
          value: "name",
          width: "250px",
        },

        {
          text: "จำนวนคงเหลือ",
          align: "center",
          sortable: false,
          value: "quantityAvailable",
        },
        { text: "ลักษณะ", align: "center", sortable: false, value: "prop1" },
        {
          text: "รหัสสินค้า",
          align: "center",
          sortable: false,
          value: "productCode",
        },
        { text: "SKU", align: "center", sortable: false, value: "sku" },
        { text: "Barcode", align: "center", sortable: false, value: "barcode" },
      ],
      dataset: [],
      selectProductList: [],
      productIdList: [],
    },
    reportOrderDataSet: {
      dataset: [],
      selected: [],
      productList: [],
      mapProductList: [],
      headerIN: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
          label: "No",
          field: "no",
        },
        {
          text: "รูป",
          align: "left",
          sortable: false,
          value: "photoLink",

          label: "รูป",
          field: "photoLink",
        },
        {
          text: "ชื่อ",
          align: "left",
          sortable: false,
          value: "name",
          label: "ชื่อ",
          field: "name",
        },
        {
          text: "จำนวน",
          align: "center",
          sortable: false,
          value: "quantity",
          width: "75px",
          label: "จำนวน",
          field: "quantity",
        },
        {
          text: "SKU",
          align: "center",
          sortable: false,
          value: "sku",
          width: "85px",
          label: "SKU",
          field: "sku",
        },
        {
          text: "barcode",
          align: "center",
          sortable: false,
          value: "barcode",
          width: "85px",
          label: "barcode",
          field: "barcode",
        },
        {
          text: "รหัส CF",
          align: "center",
          sortable: false,
          value: "cfCode",
          width: "90px",
          label: "รหัส CF",
          field: "cfCode",
        },
        {
          text: "ชั้นวาง",
          align: "center",
          sortable: false,
          value: "rackNo",
          width: "90px",
          label: "ชั้นวาง",
          field: "rackNo",
        },
      ],
      headerExcel: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
          label: "No",
          field: "no",
        },
        {
          text: "รูป",
          align: "left",
          sortable: false,
          value: "photoLink", 
          label: "รูป",
          field: "photoLink",
        },
        {
          text: "ชื่อ",
          align: "left",
          sortable: false,
          value: "productName",
          label: "ชื่อ",
          field: "productName",
        },
        {
          text: "ลักษณะ1",
          align: "left",
          sortable: false,
          value: "prop1Description",
          label: "ลักษณะ1",
          field: "prop1Description",
        },
        {
          text: "ลักษณะ2",
          align: "left",
          sortable: false,
          value: "prop2Description",
          label: "ลักษณะ2",
          field: "prop2Description",
        },
        {
          text: "จำนวน",
          align: "center",
          sortable: false,
          value: "quantity",
          width: "75px",
          label: "จำนวน",
          field: "quantity",
        },
        {
          text: "SKU",
          align: "center",
          sortable: false,
          value: "sku",
          width: "85px",
          label: "SKU",
          field: "sku",
        },
        {
          text: "barcode",
          align: "center",
          sortable: false,
          value: "barcode",
          width: "85px",
          label: "barcode",
          field: "barcode",
        },
        {
          text: "รหัส CF",
          align: "center",
          sortable: false,
          value: "cfCode",
          width: "90px",
          label: "รหัส CF",
          field: "cfCode",
        },
        {
          text: "ชั้นวาง",
          align: "center",
          sortable: false,
          value: "rackNo",
          width: "90px",
          label: "ชั้นวาง",
          field: "rackNo",
        },
      ],
      header: [],
    },
    reportOrderPopup: {
      dialog: false,
      MaxWidth: "50%",
      MaxHeight: 70,
    },
    approveDialogWidth: "500px",
    changeStatusDataSet: {
      dataset: [],
      datasetError: [],
      datasetState: [],
      selected: [],
      productList: [],
      mapProductList: [],
      loading: false,
      loadingPercent: false,
      changeStatus: null,
      success: 0,
      error: 0,
      header: [
        {
          text: "เลขออเดอร์ ",
          align: "left",
          sortable: false,
          value: "orderIdText",
          width: "120px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "120px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "referenceSourceLogo",
          width: "80px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyLogo",
          width: "80px",
        },
        {
          text: "TrackingNo ",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "120px",
        },
        {
          text: "สถานะ",
          align: "left",
          sortable: false,
          value: "statusName",
          width: "100px",
        },
        {
          text: "การดำเนินงาน",
          align: "left",
          sortable: false,
          value: "processing",
          width: "150px",
        },
      ],
      headerState: [
        {
          text: "ลำดับ",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "Order ID",
          align: "left",
          sortable: false,
          value: "orderMasterID",
          width: "80px",
        },
        {
          text: "สถานะดำเนินการ",
          align: "left",
          sortable: false,
          value: "name",
          width: "120px",
        },
        {
          text: "trackingNo",
          align: "center",
          sortable: false,
          value: "trackingNo",
          width: "75px",
        },
      ],
    },
    changeStatusPopup: {
      dialog: false,
      MaxWidth: "80%",
      MaxHeight: 70,
      tab_list: [
        { id: 0, name: "ดำเนินการ" },
        { id: 1, name: "ข้อมูลที่ไม่สำเร็จ" },
      ],
      tab: 0,
    },
    sortedPackageIDList: [],
    backStatusDataSet: {
      dataset: [],
      datasetError: [],
      datasetState: [],
      selected: [],
      productList: [],
      mapProductList: [],
      loading: false,
      loadingPercent: false,
      changeStatus: null,
      success: 0,
      error: 0,
      header: [
        {
          text: "เลขออเดอร์ ",
          align: "left",
          sortable: false,
          value: "orderIdText",
          width: "120px",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
          width: "120px",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "referenceSourceLogo",
          width: "80px",
        },
        {
          text: "ขนส่ง",
          align: "center",
          sortable: false,
          value: "expressCompanyLogo",
          width: "80px",
        },
        {
          text: "TrackingNo ",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "120px",
        },
        {
          text: "สถานะ",
          align: "left",
          sortable: false,
          value: "statusName",
          width: "100px",
        },
        {
          text: "การดำเนินงาน",
          align: "left",
          sortable: false,
          value: "processing",
          width: "150px",
        },
      ],
      headerState: [
        {
          text: "ลำดับ",
          align: "center",
          sortable: false,
          value: "no",
          width: "70px",
        },
        {
          text: "Order ID",
          align: "left",
          sortable: false,
          value: "orderMasterID",
          width: "80px",
        },
        {
          text: "สถานะดำเนินการ",
          align: "left",
          sortable: false,
          value: "name",
          width: "120px",
        },
        {
          text: "trackingNo",
          align: "center",
          sortable: false,
          value: "trackingNo",
          width: "75px",
        },
      ],
    },
    backStatusPopup: {
      dialog: false,
      MaxWidth: "60%",
      MaxHeight: 70,
      tab_list: [
        { id: 0, name: "ดำเนินการ" },
        { id: 1, name: "ข้อมูลที่ไม่สำเร็จ" },
      ],
      tab: 0,
    },
    UpdateToMarketplaceDataSet: {
      dataset: [],
      datasetError: [],
      datasetState: [],
      selected: [],
      productList: [],
      mapProductList: [],
      loading: false,
      loadingPercent: false,
      changeStatus: null,
      dropoff: null,
      pickup: null,
      addressID: null,
      selectedAddress: [],
      selectedpickupTime: [],
      pickupTimeID: [],
      shopeeType: 0,
      tiktokType: 0,
      success: 0,
      error: 0,
      handover_method: null,
      header: [
        {
          text: "ออเดอร์ ",
          align: "left",
          sortable: false,
          value: "orderMasterID",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
        },
        {
          text: "ช่องทาง",
          align: "left",
          sortable: false,
          value: "referenceSourceLogo",
        },
        {
          text: "บริการขนส่ง",
          align: "left",
          sortable: false,
          value: "expressCompanyLogo",
        },
        {
          text: "TrackingNo",
          align: "left",
          sortable: false,
          value: "trackingNo",
        },
        {
          text: "สถานะ",
          align: "left",
          sortable: false,
          value: "processing",
        },
      ],

    },
    UpdateToMarketplacePopup: {
      dialog: false,
      MaxWidth: "60%",
      MaxHeight: 70,
      marketplaceName: null,
      marketplaceType: 0,
      tab_list: [
        { id: 0, name: "ดำเนินการ" },
        { id: 1, name: "ข้อมูลที่ไม่สำเร็จ" },
      ],
      tab: 0,
    },
    printAwsDataSet: {
      dataset: [],
      datasetError: [],
      loading: false,
      loadingPercent: false,
      success: 0,
      error: 0,

      header: [
        {
          text: "ออเดอร์ ",
          align: "left",
          sortable: false,
          value: "orderMasterID",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "referenceNumber",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "referenceSourceLogo",
          width: "80px",
        },
        {
          text: "บริการขนส่ง",
          align: "left",
          sortable: false,
          value: "expressCompanyLogo",
        },
        {
          text: "TrackingNo ",
          align: "left",
          sortable: false,
          value: "trackingNo",
          width: "120px",
        },
        {
          text: "สถานะ",
          align: "left",
          sortable: false,
          value: "processing",
        },
      ],
    },
    printAwsPopup: {
      dialog: false,
      MaxWidth: "60%",
      MaxHeight: 70,
      tab_list: [
        { id: 0, name: "ดำเนินการ" },
        { id: 1, name: "ข้อมูลที่ไม่สำเร็จ" },
      ],
      tab: 0,
    },
    allocateStockDataSet: {
      dataset: [],
      datasetError: [],
      loading: false,
      loadingPercent: false,
      success: 0,
      error: 0,
      header: [
        {
          text: "ออเดอร์ ",
          align: "left",
          sortable: false,
          value: "orderMasterID",
        },
        {
          text: "เลขอ้างอิง",
          align: "left",
          sortable: false,
          value: "packageReferenceNumber",
        },
        {
          text: "ช่องทาง",
          align: "center",
          sortable: false,
          value: "referenceSourceLogo",
          width: "80px",
        },
        {
          text: "บริการขนส่ง",
          align: "left",
          sortable: false,
          value: "expressCompanyLogo",
        },
        {
          text: "สถานะ",
          align: "left",
          sortable: false,
          value: "processing",
        },
      ],
      ListSelect: [
        { id: 0, name: "ตามลำดับตัดสต๊อก", value: 1 },
        { id: 1, name: "FEFO, FIFO", value: 2 },
        { id: 2, name: "FIFO", value: 3 },
      ],
      Select: { id: 0, name: "ตามลำดับตัดสต๊อก", value: 1 },
    },
    allocateStockPopup: {
      dialog: false,
      MaxWidth: "60%",
      MaxHeight: 70,
      tab_list: [
        { id: 0, name: "ดำเนินการ" },
        { id: 1, name: "ข้อมูลที่ไม่สำเร็จ" },
      ],
      tab: 0,
    },
    PackingStatus: [
      { ID: 10, Name: "รอผูกสินค้า" },
      { ID: 20, Name: "รอจองสต๊อก" },
      { ID: 30, Name: "รอนัดรับ" },
      { ID: 40, Name: "รอหยิบ" },
      { ID: 50, Name: "รอแพ็ค" },
      { ID: 60, Name: "กำลังแพ็ค" },
      { ID: 80, Name: "รอขนส่งเข้ารับ" },
      { ID: 90, Name: "กำลังจัดส่ง" },
      { ID: 100, Name: "กำลังตีกลับ" },
      { ID: 110, Name: "ตีกลับแล้วรอคืนสต๊อก" },
      { ID: 120, Name: "ตีกลับแล้ว คืนสต๊อกแล้ว" },
      { ID: 130, Name: "จัดส่งสำเร็จ" },
      { ID: 140, Name: "ยกเลิก" },
    ],

    iconStatusPack: [
      { ID: 10, Name: "รอผูกสินค้า", icon: "mdi-package-variant" },
      { ID: 20, Name: "รอจองสต๊อก", icon: "mdi-package-variant" },
      { ID: 30, Name: "รอนัดรับ", icon: "mdi-package-variant" },
      { ID: 40, Name: "รอหยิบ", icon: "mdi-package-variant" },
      { ID: 50, Name: "รอแพ็ค", icon: "mdi-package-variant" },
      { ID: 60, Name: "กำลังแพ็ค", icon: "mdi-package-variant" },
      { ID: 80, Name: "รอขนส่งเข้ารับ", icon: "mdi-restore" },
      { ID: 90, Name: "กำลังจัดส่ง", icon: "mdi-truck" },
      { ID: 100, Name: "กำลังตีกลับ", icon: "mdi-undo" },
      { ID: 110, Name: "ตีกลับแล้วรอคืนสต๊อก", icon: "mdi-package-variant" },
      { ID: 120, Name: "ตีกลับแล้ว คืนสต๊อกแล้ว", icon: "mdi-cached" },
      { ID: 130, Name: "จัดส่งสำเร็จ", icon: "mdi-check-circle" },
      { ID: 140, Name: "ยกเลิก", icon: "mdi-close-circle" },
    ],
    manageMenuList: [
      { name: "ผูกสินค้า", btn: "btnMappedProduct" },
      { name: "กรองสินค้า", btn: "btnFilterOrder" },
      { name: "จองสต๊อก", btn: "btnAllocateStock" },
      { name: "พร้อมจัดส่งไปยัง Marketplace", btn: "btnUpdateToMarketplace" },
      { name: "ปริ้นใบแพ็ค", btn: "btnPrintBarcode" },
      { name: "ปริ้นใบปะหน้า", btn: "btnPrintAwb" },
      { name: "เปลี่ยนสถานะ", btn: "btnPackageChangeStatus" },
      { name: "ย้อนสถานะ", btn: "btnPackageBackStatus" },
      { name: "รายงาน", btn: "btnViewReport" },
      { name: "ส่งออกรายงานออเดอร์ฺ", btn: "btnExportExcel" },
      { name: "สต๊อก", btn: "" },
    ],
    OrderExportExcelHeader: [
      { label: "No", field: "no" },
      { label: "เลขออเดอร์", field: "orderMasterIDText" },
      { label: "เลขแพ็กเกจ", field: "packageIDText" },
      { label: "เลขอ้างอิง", field: "referenceNumber" },
      { label: "สถานะพัสดุ", field: "packageStatusName" },
      { label: "วันที่สร้าง", field: "createdDatetime" },
      { label: "วันที่ต้องจัดส่ง", field: "dueDeliveryDate" },
      { label: "วันที่พร้อมแพ็ก", field: "readyToPackedDate" },
      { label: "วันที่ปริ้นใบแพ็ก", field: "printPackingListTime" },
      { label: "วันที่ปริ้นใบปะหน้า", field: "printAWBTime" },
      { label: "เริ่มแพ็ก", field: "startPackingDate" },
      { label: "แพ็กเสร็จ", field: "packedDate" },
      { label: "คนแพ็ก", field: "packedByStaffBranchName" },
      { label: "แพ็กสำเร็จจากการย้ายสถานะ", field: "packedByStatusMoveDate" },
      { label: "พนักงานที่ทำการย้ายสถานะ", field: "PackedByStatusMoveStafBranchName" },
      { label: "วันที่จัดส่งให้ขนส่ง", field: "sendToExpressDateTime" },
      { label: "วันที่จัดส่งถึงผู้รับสำเร็จ", field: "parcelSuccessDateTime" },
      { label: "วันที่ตีกลับ", field: "returnedDateTime" },
      { label: "ร้านค้า", field: "shopName" },
      { label: "ช่องทาง", field: "channelName" },
      { label: "ขนส่ง", field: "expressCompanyName" },
      { label: "TrackingNo", field: "trackingNo" },
      { label: "COD", field: "isCOD" },
      { label: "ยอด COD", field: "codAmount" },
      { label: "ยอดทำประกันขนส่ง", field: "insuranceAmount" },
      { label: "ชื่อผู้ส่ง", field: "senderName" },
      { label: "เบอร์ผู้ส่ง", field: "senderPhone" },
      { label: "ที่อยู่ผู้ส่ง", field: "senderAddress" },
      { label: "ตำบลผู้ส่ง", field: "senderSubDistrict" },
      { label: "อำเภอผู้ส่ง", field: "senderDistrict" },
      { label: "จังหวัดผู้ส่ง", field: "senderProvince" },
      { label: "รหัสไปรษณีผู้ส่ง", field: "senderPostcode" },

      { label: "ชื่อผู้รับ", field: "receiverName" },
      { label: "เบอร์ผู้รับ", field: "receiverPhone" },
      { label: "ที่อยู่ผู้รับ", field: "receiverAddress" },
      { label: "ตำบลผู้รับ", field: "receiverSubDistrict" },
      { label: "อำเภอผู้รับ", field: "receiverDistrict" },
      { label: "จังหวัดผู้รับ", field: "receiverProvince" },
      { label: "รหัสไปรษณีผู้รับ", field: "receiverPostcode" },

      { label: "ค่าขนส่ง", field: "deliveryPrice" },
      { label: "ค่าพื้นที่ห่างไกล", field: "remotePrice" },
      { label: "ค่าบริการ COD", field: "codCharged" },
      { label: "ค่าแพ็ค", field: "packPrice" },
      { label: "ค่ากล่อง", field: "boxPrice" },
      { label: "ค่าบับเบิ้ล", field: "bubblePrice" },
      { label: "ค่าเชื่อมต่อ", field: "vrichTransactionFee" },
      { label: "ส่วนลด", field: "discount" },
      { label: "ค่าบริการอื่นๆ", field: "otherAmount" },
      { label: "รวมค่าบริการ", field: "sumServiceAmount" },
      { label: "น้ำหนัก", field: "weight" },
      { label: "กว้าง", field: "cubic1" },
      { label: "ยาว", field: "cubic2" },
      { label: "สูง", field: "cubic3" },
      { label: "กล่อง", field: "boxName" },
      { label: "จำนวนสินค้าทั้งหมด", field: "productCount" },
      { label: "จำนวน SKU ทั้งหมด", field: "skuCount" },
      { label: "พัสดุที่", field: "packageNo" },
      { label: "สินค้า", field: "productName" },
      { label: "prop1Description", field: "prop1Description" },
      { label: "prop2Description", field: "prop2Description" },
      { label: "รหัสสินค้า", field: "productCode" },
      { label: "SKU", field: "sku" },
      { label: "SerialNo", field: "serialNumber" },
      { label: "Barcode", field: "barcode" },
      { label: "จำนวน", field: "quantity" },
      { label: "ราคาสินค้าต่อชิ้น", field: "unitPrice" },
      { label: "หมายเหตุ", field: "remark" },
      { label: "จำนวนที่ตีกลับ", field: "returnQuantity" },
    ],
  }),
  computed: {
    showHeaders() {
      return this.dataTable.headers.filter((s) => this.dataTable.headers.includes(s));
    },
    showManageMenuList() {
      const kerWordShow = this.manageMenuListType(this.selectedTab);
      return this.manageMenuList.filter((item) => kerWordShow.includes(item.name));
    },
    showManageChangeStatusList() {
      const keyWordShow = this.ManageChangeStatusList(this.selectedTab);
      return this.PackingStatus.filter((item) => keyWordShow.includes(item.Name));
    },
    showManageBackStatusList() {
      const keyWordShow = this.ManageBackStatusList(this.selectedTab);
      return this.PackingStatus.filter((item) => keyWordShow.includes(item.Name));
    },

    countreportOrderDataSet() {
      if (this.reportOrderDataSet.dataset.length > 0) {
        const totalQuantity = this.reportOrderDataSet.dataset.reduce((total, product) => {
          if (product.quantity !== undefined && product.quantity !== null) {
            return total + product.quantity;
          } else {
            return total;
          }
        }, 0);

        return totalQuantity;
      } else {
        return 0;
      }
    },
  },
  watch: {
    async selectedTab() {
      this.search.keyword = {
        packageStatusID: this.selectedTab,
      };

      this.search.exprees = [];
      this.search.channelSourceList = [];
      this.search.groupPrintList = [];
      this.search.shopList = [];
      this.CFDateFromSelected =null;
      this.CFDateFromText=null;

      this.CFDateToSelected=null;
      this.CFDateToText=null;

      this.ReadyPackDateFromText=null;
      this.ReadyPackDateFromSelected=null;

      this.ReadyPackDateToText=null;
      this.ReadyPackDateToSelected=null;

      this.DueDeliveryDateFromText=null;
      this.DueDeliveryDateFromSelected=null;

      this.DueDeliveryDateToText=null;
      this.DueDeliveryDateToSelected=null;


      this.sortedPackageIDList =[]
      this.dataTable.selected = [];
      await this.resetSearchOrder();
      await this.getCountOrderPackingList();
      this.progressBarActive=true;
      this.getPercentLoading(1, 2);
      await this.SearchOrder(this.offsets, this.limits);
      this.getPercentLoading(2, 2);
      this.progressBarActive=false;
    },
    async page() {
      var keyword = this.search.keyword;
      keyword.isSelectAll = false;
      this.search.keyword = keyword;

      if (this.pageLength != 1 && this.pageTemp != this.page) {
        // this.loading = true;
        this.offsets = this.page * this.limits - this.limits;
        if (this.offsets == 0) {
          this.page = 1;
        }
        this.progressBarActive=true;
        await this.getPercentLoading(1, 2);
        await this.SearchOrder(this.offsets, this.limits);
        await this.getCountOrderPackingList();
        await this.getPercentLoading(2, 2);
        this.progressBarActive=false;
        // this.loading = false;
      }
    },
    async "addStockPopup.page"() {
      if (
        this.addStockPopup.pageLength != 1 &&
        this.addStockPopup.pageTemp != this.addStockPopup.page
      ) {
        var offsets =
          this.addStockPopup.page * this.addStockPopup.limits - this.addStockPopup.limits;
        var dataSetAdd = await this.getProductItemList(
          this.addStockPopup.limits,
          offsets,
          this.addStockPopup.shopID,
          this.popupsearch
        );
        this.addStockPopup.dataset = dataSetAdd.productList;
        this.addStockPopup.pageTemp = this.addStockPopup.page;
      }
    },
  },

  async created() {
    if(this.BranchID==640){
      this.OrderExportExcelHeader.splice(21,0, { label: "DHL Reference", field: "sortcode5" },)
    }
    (this.staffBranchId = localStorage.getItem("Branch_StaffID")),
      (this.staffShopID = localStorage.getItem("staff_id"));
    this.orderbyText = await this.GetViewTypePage('product-pack-list');
    await this.GetWarehouse();
    await this.Load_Data();
    await this.getCountOrderPackingList();
    this.dataTable.headers = [...this.dataTable.header];
    this.search.keyword = { packageStatusID: this.selectedTab };
    this.progressBarActive=true;
    await this.getPercentLoading(1, 2);
    await this.SearchOrder(0, this.limits);
    await this.getPercentLoading(2, 2);
    this.progressBarActive=false;
    this.page_loading = false;

    if (this.orderbyText) {
      var splited = this.orderbyText.split(',');
      for (let index = 0; index < splited.length; index++) {

        if (index == 0) {
          this.sortAbleSelected1 = splited[index].split(':')[0];
          this.sortTypeSelected1 = splited[index].split(':')[1];
        }
        else if (index == 1) {
          this.sortAbleSelected2 = splited[index].split(':')[0];
          this.sortTypeSelected2 = splited[index].split(':')[1];
        }
        else if (index == 2) {
          this.sortAbleSelected3 = splited[index].split(':')[0];
          this.sortTypeSelected3 = splited[index].split(':')[1];
        }
        else if (index == 3) {
          this.sortAbleSelected4 = splited[index].split(':')[0];
          this.sortTypeSelected4 = splited[index].split(':')[1];
        }
        else if (index == 4) {
          this.sortAbleSelected5 = splited[index].split(':')[0];
          this.sortTypeSelected5 = splited[index].split(':')[1];
        }
        else if (index == 5) {
          this.sortAbleSelected6 = splited[index].split(':')[0];
          this.sortTypeSelected6 = splited[index].split(':')[1];
        }

      }
    }

   
   

  },
  methods: {
    //ส่วนของ ฟังก์ชั่นเช็คตรวจสอบ
    
    async spliceSearchOrder(index, limits, orderIdList) {
      // this.loading = true;
      // ในกรณีเลือกทั้งหมด
      this.progressBarActive=true;
      if (limits == 999999) {
        let amount_of_data_each = 50; // จำนวนข้อมูลที่มีในแต่ละก้อน
        let IsStop = false;
        let skip = 0;
        let Take = amount_of_data_each;
        let loopCountFetch = Math.ceil(this.total / amount_of_data_each);
        let count = 1;
        let objList = [];
        this.offsets = 0;

        while (!IsStop) {
          let body = { isHasMore: true };
          if (body.isHasMore) {
            body = await this.SearchOrder(skip, Take, null, true);
            objList.push(...body.resultData);
            skip += amount_of_data_each;
            await this.getPercentLoading(count, loopCountFetch);
            count++;
          }

          if (!body.isHasMore) {
            IsStop = true;
          }
        }
       
        
        this.total = objList.length;
        this.pageLength = 1;
        this.dataTable.items = objList;
        // this.loading = false;
      } else {
        await this.getPercentLoading(1, 2);
        await this.SearchOrder(index, limits, orderIdList, false);
        await this.getPercentLoading(2, 2);
      }
      this.progressBarActive=false;
    },
    ShowDetailOrder(id, orderToken) {
      window.open("/order-detail?id=" + id);
    },
    checkLatestLimitType() {
      if (this.latestLimitType == 2) {
        this.limits = 10;
        this.latestLimitType = 1;
      }
    },
    async GetWarehouse() {
      let response = await axios.get(
        branchService_dotnet + "Branch/get-warehouse-list?BranchID=" + this.BranchID,
        { headers: this.header_token }
      );
      if (response.status == 200) {
        this.search.warehouseIDList = [];
        this.warehouseList_filter = [];
        if (response.data.warehouseList.length > 0) {
          if (response.data.warehouseList.length > 1) {
            this.warehouseList_filter = [{ id: 0, name: "--ทั้งหมด--" }];
          }
          for (var i in response.data.warehouseList) {
            this.warehouseID_selected.push(response.data.warehouseList[i].id);
            this.warehouseList_filter.push({
              id: response.data.warehouseList[i].id,
              name: response.data.warehouseList[i].name,
            });
          }
        }
        this.search.warehouse = this.warehouseList_filter[0];
      }
    },
    async resetSearchOrder(btnSearchOrder) {

      
     

      this.page = 1;
      this.dataTable.items = [];
      this.filteOrderDataSet.filteOrderList = [];
      this.offsets = 0;
      (this.limits = 10),
        (this.search.select_exprees = { id: null, name: " -- ทั้งหมด --" });
      this.search.exprees = [{ id: null, name: " -- ทั้งหมด --" }];
      this.search.channelSource = {
        channelID: null,
        channelName: "--ทุกช่องทาง--",
      };
      this.search.channelSourceList = [
        { channelID: null, channelName: "--ทุกช่องทาง--" },
      ];
      this.search.keyword = {
        packageStatusID: this.selectedTab,
      };
      this.dataTable.selected = [];
      if (btnSearchOrder != true) {
        this.search.select_topic = {
          id: 1,
          name: "เลขออเดอร์",
          value: "orderId",
        };
        this.search.text_topic = "";
      }
      this.search.shop = { shopID: null, shopName: "--ทั้งหมด--" };
      (this.search.groupPrint = { id: null, name: " -- ทั้งหมด --" }),
        (this.search.groupPrintList = [{ id: null, name: " -- ทั้งหมด --" }]),
        (this.latestLimitType = 1);
    },
    async getPercentLoading(Round, countLoop) {
      this.percentLoading = 0;
      // let countLoop = 0; //จำนวนที่วนรอบ
      // let Round  =0; //จำนวนปัจจุบัน
      this.percentLoading = parseInt((Round / countLoop) * 100);
      if (this.percentLoading == 100) {
        this.percentLoadingText = "สำเร็จ";
      } else if (this.percentLoading > 0) {
        this.percentLoadingText = "กำลังโหลด";
      } else {
        this.percentLoadingText = "รอโหลด";
      }
    },
    async getPackingType(item) {
      // console.log("item.statusID",item.statusID);
      this.selectedTab = item.statusID;
      this.changeFormat_DataTabel(item.statusID);
    },
    getIconPack(statusID) {
      const matchedItem = this.iconStatusPack.find((item) => item.ID === statusID);
      return matchedItem ? matchedItem.icon : "mdi-package-variant"; // Default icon
    },
    async changeFormat_DataTabel(statusID) {
      //รับ statusID มาเช็คว่าตรงไหมถ้ามีตามที่ต้องลบก็จะลบ
      let columns = this.dataTable.removeColumn.find((r) => r.ID == statusID);
      let remove = columns ? columns.remove : null;

      this.dataTable.headers = [...this.dataTable.header];

      if (remove) {
        for (var item in remove) {
          let index = this.dataTable.headers.findIndex((x) => x.text === remove[item]);

          if (index != -1) {
            this.dataTable.headers.splice(index, 1);
          }
        }
      }
    },
    async handleClickOutside(item) {
      if (item.btn && typeof this[item.btn] === "function") {
        this[item.btn]();
      }
    },
    manageMenuListType(statusID) {
      let kerWordShow = [];

      switch (statusID) {
        //ผูกสินค้า
        case 10:
          kerWordShow = ["ผูกสินค้า", "รายงาน", "เปลี่ยนสถานะ"];
          break;
        //รอจองสต๊อก
        case 20:
          kerWordShow = ["กรองสินค้า", "จองสต๊อก", "รายงาน", "เปลี่ยนสถานะ"];
          break;
        //รอนับรับ
        case 30:
          kerWordShow = [
            "กรองสินค้า",
            "พร้อมจัดส่งไปยัง Marketplace",
            "รายงาน",
            "เปลี่ยนสถานะ",
          ];
          break;
        //รอหยิบ
        case 40:
          if (this.isAllowPrintAWBBeforePack == true) {
            kerWordShow = [
              "กรองสินค้า",
              "ปริ้นใบแพ็ค",
              "ปริ้นใบปะหน้า",
              "เปลี่ยนสถานะ",
              "รายงาน",
            ];
          } else {
            kerWordShow = ["กรองสินค้า", "ปริ้นใบแพ็ค", "เปลี่ยนสถานะ", "รายงาน"];
          }
          break;
        //กำลังแพ็ค
        case 60:
          if (this.isAllowPrintAWBBeforePack == true) {
            kerWordShow = [
              "กรองสินค้า",
              "ปริ้นใบแพ็ค",
              "ปริ้นใบปะหน้า",
              "ย้อนสถานะ",
              "เปลี่ยนสถานะ",
              "รายงาน",
            ];
          } else {
            kerWordShow = [
              "กรองสินค้า",
              "ปริ้นใบแพ็ค",
              "ย้อนสถานะ",
              "เปลี่ยนสถานะ",
              "รายงาน",
            ];
          }
          break;
        //รอขนส่งเข้ารับ
        case 80:
          kerWordShow = [
            "ปริ้นใบแพ็ค",
            "ปริ้นใบปะหน้า",
            "ย้อนสถานะ",
            "เปลี่ยนสถานะ",
            "รายงาน",
          ];

          break;
        //กำลังจัดส่ง
        case 90:
          kerWordShow = ["ย้อนสถานะ", "เปลี่ยนสถานะ", "รายงาน"];
          break;
        //กำลังตีกลับ
        case 100:
          kerWordShow = ["ย้อนสถานะ", "เปลี่ยนสถานะ", "รายงาน"];
          break;
        //ตีกลับแล้วรอคืนสต๊อก
        case 110:
          kerWordShow = ["เปลี่ยนสถานะ", "รายงาน", "สต๊อก"];
          break;
        //ตีกลับแล้ว คืนสต๊อกแล้ว
        case 120:
          kerWordShow = ["เปลี่ยนสถานะ", "รายงาน"];
          break;
        //จัดส่งสำเร็จ
        case 130:
          kerWordShow = [ "กรองสินค้า","รายงาน"];
          break;
        //ยกเลิก
        case 140:
          kerWordShow = ["ย้อนสถานะ", "รายงาน"];
          break;
      }

      return kerWordShow;
    },
    ManageChangeStatusList(statusID) {
      let keyWordShow = [];

      switch (statusID) {
        case 10:
          keyWordShow = [
            "รอจองสต๊อก",
            "รอนัดรับ",
            "รอหยิบ",
            "กำลังแพ็ค",
            "รอขนส่งเข้ารับ",
            "กำลังจัดส่ง",
            "จัดส่งสำเร็จ",
            "ยกเลิก",
          ];
          break;
        //รอจองสต๊อก
        case 20:
          keyWordShow = [
            "รอนัดรับ",
            "รอหยิบ",
            "กำลังแพ็ค",
            "รอขนส่งเข้ารับ",
            "กำลังจัดส่ง",
            "จัดส่งสำเร็จ",
            "ยกเลิก",
          ];
          break;
        //รอนัดรับ
        case 30:
          keyWordShow = ["รอหยิบ", "กำลังแพ็ค", "รอขนส่งเข้ารับ", "กำลังจัดส่ง", "จัดส่งสำเร็จ", "ยกเลิก"];
          break;
        //รอหยิบ
        case 40:
          keyWordShow = ["กำลังแพ็ค", "รอขนส่งเข้ารับ", "กำลังจัดส่ง", "จัดส่งสำเร็จ", "ยกเลิก"];
          break;
        //กำลังแพ็ค
        case 60:
          keyWordShow = ["รอขนส่งเข้ารับ", "กำลังจัดส่ง", "จัดส่งสำเร็จ", "ยกเลิก"];
          break;
        //รอขนส่งเข้ารับ
        case 80:
          keyWordShow = ["กำลังจัดส่ง", "จัดส่งสำเร็จ", "กำลังตีกลับ", "ยกเลิก"];
          break;
        //กำลังจัดส่ง
        case 90:
          keyWordShow = ["จัดส่งสำเร็จ", "กำลังตีกลับ", "ตีกลับแล้วรอคืนสต๊อก", "ยกเลิก"];
          break;
        //กำลังตีกลับ
        case 100:
          keyWordShow = ["ตีกลับแล้วรอคืนสต๊อก", "ตีกลับแล้ว คืนสต๊อกแล้ว"];
          break;
        //ตีกลับแล้วรอคืนสต๊อก
        case 110:
          keyWordShow = ["กำลังตีกลับ", "ตีกลับแล้ว คืนสต๊อกแล้ว"];
          break;
        //ตีกลับแล้ว คืนสต๊อกแล้ว
        case 120:
          keyWordShow = ["กำลังตีกลับ", "ตีกลับแล้วรอคืนสต๊อก"];
          break;
        //ยกเลิก
        case 140:
          keyWordShow = ["กำลังแพ็ค"];
          break;
      }

      return keyWordShow;
    },
    ManageBackStatusList(statusID) {
      let kerWordShow = [];

      switch (statusID) {
        //กำลังแพ็ค
        case 60:
          kerWordShow = ["รอหยิบ"];
          break;
        //รอขนส่งเข้ารับ
        case 80:
          kerWordShow = ["กำลังแพ็ค"];
          break;
        //กำลังจัดส่ง
        case 90:
          kerWordShow = ["เตรียมจัดส่ง"];
          break;

        //ยกเลิก
        case 140:
          kerWordShow = ["กำลังแพ็ค"];
          break;
      }

      return kerWordShow;
    },
    isDisabled(item) {
      if (this.dataTable.selected.length > 0) {
        if (this.selectedTab == 40 || this.selectedTab == 60) {
          if (item.name == "ปริ้นใบปะหน้า" && this.isAllowPrintAWBBeforePack == false) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true; // Disable the item
      }
    },
    async rowClick(item) {
      this.MappedProductsDataSet.selectedToMap = [item];
      this.MappedProductsDataSet.productLisDataSet =
        item.productMapped == null ? [] : [...item.productMapped];
    },
    async refreshWindow(page) {
      this.sortedPackageIDList =[]
      await this.resetSearchOrder();
      await this.getCountOrderPackingList();
      this.progressBarActive=true;
      this.getPercentLoading(1, 2);
      await this.SearchOrder(this.offsets, this.limits);
      this.dataTable.selected=[]
      let data = [];
      data.statusID = this.selectedTab;
      this.getPackingType(data);
      switch (page) {
        case "MappedProductsPopup":
          this.MappedProductsDataSet.loading = false;
          break;

        case "changeStatusPopup":
          this.changeStatusDataSet.loadingPercent = false;
          this.changeStatusDataSet.loading = false;
          this.changeStatusDataSet.success = 0;
          this.changeStatusDataSet.error = 0;
          this.changeStatusDataSet.datasetError = [];
          break;

        case "backStatusPopup":
          this.backStatusDataSet.loadingPercent = false;
          this.backStatusDataSet.loading = false;
          this.backStatusDataSet.success = 0;
          this.backStatusDataSet.error = 0;
          this.backStatusDataSet.datasetError = [];
          break;

        case "UpdateToMarketplacePopup":
          this.UpdateToMarketplaceDataSet.loading = false;
          this.UpdateToMarketplaceDataSet.loadingPercent = false;
          this.UpdateToMarketplaceDataSet.success = 0;
          this.UpdateToMarketplaceDataSet.error = 0;
          this.UpdateToMarketplaceDataSet.datasetError = [];
          break;

        case "printAwsPopup":
          this.printAwsDataSet.loadingPercent = false;
          this.printAwsDataSet.loading = false;
          this.printAwsDataSet.success = 0;
          this.printAwsDataSet.error = 0;
          this.printAwsDataSet.datasetError = [];
          break;

        case "allocateStockPopup":
          this.allocateStockDataSet.loadingPercent = false;
          this.allocateStockDataSet.loading = false;
          this.allocateStockDataSet.success = 0;
          this.allocateStockDataSet.error = 0;
          this.allocateStockDataSet.datasetError = [];
          this.allocateStockDataSet.Select = null;

          break;
      }
      this.getPercentLoading(2, 2);
      this.progressBarActive=false;
    },
    handleAddressChange() {
      this.UpdateToMarketplaceDataSet.selectedpickupTime = this.UpdateToMarketplaceDataSet.pickup[
        this.UpdateToMarketplaceDataSet.selectedAddress
      ];
    },
    OpenPhoto(Photo) {
      this.ShowPhotoLink = Photo;
      this.ShowPhoto = true;
    },
    // เรียกใช้
    ClosePhoto(value) {
      this.ShowPhoto = value;
      this.ShowPhotoLink = "";
    },

    //ส่วนของ API ยิง

    async Load_Data() {
      await axios
        .all([
          axios.post( shopService_dotnet + "Shop/get-shop-list",{ shopType: 1,branchId: this.BranchID,},{ headers: this.header_token }),
          axios.post(
            branchService_dotnet + "Branch/get-warehouse-list",
            { BranchID: parseInt(localStorage.getItem("Branch_BranchID")) },
            { headers: this.header_token }
          ),
          axios.post(
            branchService_dotnet + "Branch/get-branch-single",
            { branchId: parseInt(localStorage.getItem("Branch_BranchID")) },
            { headers: this.header_token }
          ),
        ])
        .then(
          axios.spread((shopList,warehouseID, getBranchID) => {
            if (warehouseID.status == 200 && getBranchID.status == 200) {
              if (shopList.data.result.length > 0) {
                for (var i in shopList.data.result) {
                  this.search.shopListDefault.push({
                    shopID: shopList.data.result[i].shop.id,
                    shopName: shopList.data.result[i].shop.name,
                  });
                }
              }
      
              

              // ดึง warehouseID จาก BranchID
              this.warehouseID = warehouseID.data[0].id;
              // ดึง branch จาก BranchID
              this.isAllowPrintAWBBeforePack =
                getBranchID.data.branch.isAllowPrintAWBBeforePack;
            } else {
              this.AlertError();
            }
          })
        );
    },
    async SearchOrder(index, limits, packageIDList, spliceOrder) {
      let response = await axios
        .post(
          orderService_dotnet + "Package/search-package-packing-list",
          {
            warehouseIDList: this.warehouseID_selected,
            skip: index,
            take: limits,
            checkTypePending: this.tab == 0 ? true : false,
            keyword: this.search.keyword,
            packageIDList: packageIDList,
            isNeedChannelGroup: index == 0 ? true : false,
            orderBy: this.orderbyText == '' ? null : this.orderbyText
          },
          { headers: this.header_token }
        )
        .then((res) => {
          if (res.data.status) {
           
            
            if (this.latestLimitType != 2) {
              // groupExpress
              
              if (res.data.groupExpress != null) {
                this.search.exprees = [];
                (this.search.select_exprees = {
                  expressCompanyID: undefined,
                  expressCompanyName: " -- ทั้งหมด --",
                }),
                  this.search.exprees.push({
                    expressCompanyID: undefined,
                    expressCompanyName: " -- ทั้งหมด --",
                  });
                for (var e in res.data.groupExpress) {
                  this.search.exprees.push(res.data.groupExpress[e]);
                }
              } 
              // channelSourceList 
              if (res.data.groupChannel != null) {
                this.search.channelSourceList = [];
                (this.search.channelSource = {
                  channelID: null,
                  channelName: "--ทุกช่องทาง--",
                }),
                  this.search.channelSourceList.push({
                    channelID: null,
                    channelName: "--ทุกช่องทาง--",
                  });
                for (var k in res.data.groupChannel) {
                  this.search.channelSourceList.push(res.data.groupChannel[k]);
                }
              }
              // groupPrint
              
             
              if (res.data.groupPrint != null) {
                this.search.groupPrintList = [];
                (this.search.groupPrint = { id: null, name: " -- ทั้งหมด --" }),
                  this.search.groupPrintList.push({
                    id: null,
                    name: " -- ทั้งหมด --",
                  });
                for (var p in res.data.groupPrint) {
                  this.search.groupPrintList.push(res.data.groupPrint[p]);
                }
              }
              // groupShop
             
              if (res.data.groupShop != null) {
                this.search.shopList = [];
                (this.search.shop = { shopID: null, shopName: "--ทั้งหมด--" }),
                  this.search.shopList.push({
                    shopID: null,
                    shopName: "--ทั้งหมด--",
                  });
                for (var c in res.data.groupShop) {
                  this.search.shopList.push(res.data.groupShop[c]);
                }
              }
              if (!spliceOrder) {
                if (res.data.resultData != null) {
                  this.dataTable.items = res.data.resultData;                 
                }
              

                this.total = res.data.resultCount;
                this.pageLength = Math.ceil(this.total / this.limits);
              }
            }
            if(res.data.resultData!=null){
              var sorted = res.data.resultData.map((item) => item.orderMasterPackageInfoID)
              this.sortedPackageIDList.push(...sorted);
            }else{
              this.sortedPackageIDList.push(res.data.packageIDList);
            }
           
          } else {
            this.loading = false;
            this.AlertError();
            // this.SearchOrder(index,limits,changeTab)
          }
       
          
          return res.data;
        })
        .catch((error) => {
          console.log(error);
          
          this.loading = false;
          this.AlertError();
        });

      return response;
    },
    async getFilterOrder(packageIDList) {
      return await axios
        .post(
          orderService_dotnet + "Package/get-product-tobe-filter",
          {
            packageIDList: packageIDList,
          },
          { headers: this.header_token }
        )
        .then((res) => {
          return (res.data.data = res.data.productList);
        })
        .catch((error) => {
          return null;
        });
    },
    async getFilterPackage(body) {
      return await axios
        .post(
          orderService_dotnet + "Package/filter-package",
          {
            selectedStockShopIDList: body.selectedStockShopIDList,
            notSelectedStockShopIDList: body.notSelectedStockShopIDList,
            packageIDList: body.packageIDList,
            skuFrom: body.skuFrom,
            skuTo: body.skuTo,
            quantityFrom: body.quantityFrom,
            quantityTo: body.quantityTo,
          },
          { headers: this.header_token }
        )
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          return null;
        });
    },
    async getCountOrderPackingList() {
      let response = await axios
        .post(
          orderService_dotnet + "Package/get-count-package-packing-list",
          {
            warehouseIDList: this.warehouseID_selected,
            checkTypePending: this.tab == 0 ? true : false,
            keyword: this.search.keyword,
          },
          { headers: this.header_token }
        )
        .then((res) => {
          this.packingType = [];
          // this.tab_list = res.data.packingType;
          this.PackingStatus.forEach((item) => {
            const statusType = res.data.packingTypeCount.find(
              (packingType) => item.ID === packingType.statusID
            );
            if (statusType != undefined) {
              this.packingType.push(statusType);
            }
          });
          this.tab_list = this.packingType;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    async getMappedProduct(packageIDList) {
      return await axios
        .post(
          orderService_dotnet + "Package/get-to-be-mapped-product",
          {
            packageIDList: packageIDList,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_message == null) {
            return await res.data;
          } else {
            this.AlertError(res.data.error_message);
            return null;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async getProductItemList(limits, offsets, shopId, searchstock) {
      return await axios
        .post(
          orderService_dotnet + "Package/search-to-be-mapped-product",
          {
            shopID: parseInt(shopId),
            warehouseID: this.addStockPopup.warehouseID,
            name: this.addStockPopup.SearchBy == "ชื่อสินค้า" ? searchstock : null,
            productCode: this.addStockPopup.SearchBy == "รหัสสินค้า" ? searchstock : null,
            barcode: this.addStockPopup.SearchBy == "Barcode" ? searchstock : null,
            sku: this.addStockPopup.SearchBy == "SKU" ? searchstock : null,
            skip: offsets,
            take: limits,
            skipProductMasterItemID: this.addStockPopup.productIdList,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          this.addStockPopup.pageLength = Math.ceil(
            res.data.totalProductCount / this.addStockPopup.limits
          );
          if (this.addStockPopup.pageLength <= 0) {
            this.addStockPopup.pageLength = 1;
          }
          return await res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError(error);
          return false;
        });
    },
    async saveToMapProduct(body) {
      return await axios
        .post(
          orderService_dotnet + "Package/save-to-be-mapped-product",
          { ...body },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return await res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError(error);
          return false;
        });
    },
    async saveNotMapProduct(body) {
      return await axios
        .post(
          orderService_dotnet + "Package/save-not-mapped-product",
          { ...body },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return await res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError(error);
          return false;
        });
    },
    async processOrderAfterMap(body) {
      return await axios
        .post(
          orderService_dotnet + "Package/process-order-after-mapped",
          { ...body },
          { headers: this.header_token }
        )
        .then(async (res) => {
          return await res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError(error);
          return false;
        });
    },
    async getAllReportMapProduct(packageIDList) {
      return await axios
        .post(
          orderService_dotnet + "Package/get-all-to-be-mapped-product",
          {
            packageIDList: packageIDList,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_message == null) {
            return await res.data;
          } else {
            this.AlertError(res.data.error_message);
            return null;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async getAllViewReportPack (body) {
      return await axios
        .post(
          orderService_dotnet + "Package/view-product-to-be-packed",
          {
            packageIDList: body.packageIDList,
            selectedStockShopIDList: body.selectedStockShopIDList,
            notSelectedStockShopIDList: body.notSelectedStockShopIDList,
            skuFrom: body.skuFrom,
            skuTo: body.skuTo,
            quantityFrom: body.quantityFrom,
            quantityTo: body.quantityTo,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_message == null) {
            return await res.data;
          } else {
            this.AlertError(res.data.error_message);
            return null;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async getPreparePackageBeforeChangeStatus(packageIDList) {
      return await axios
        .post(
          orderService_dotnet + "Package/prepare-package-before-change-status",
          {
            packageIDList: packageIDList,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_description == null) {
            return await res.data;
          } else {
            this.AlertError(res.data.error_description);
            return null;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async changePackageStatus(packageID, packageStatusID) {
      return await axios
        .post(
          orderService_dotnet + "Package/change-package-status",
          {
            packageID: packageID,
            packageStatusID: packageStatusID,
            staffBranchID: this.staffBranchId,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_description == null) {
            return await res.data;
          } else {
            //  this.AlertError(res.data.error_description);
            return res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async prepareUpdateToMarketplace(packageIDList) {
      return await axios
        .post(
          ecomService_dotnet + "Order/prepare-rts",
          {
            packageIDList: packageIDList,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_description == null) {
            return await res.data;
          } else {
            this.AlertError(await res.data.error_description);
            return res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async RTS_shopee_package(body) {
      return await axios
        .post(
          ecomService_dotnet + "Order/rts-shopee-order",
          {
            orderMasterID: body.orderMasterID,
            packageID: body.packageID,
            addressID: body.addressID != undefined ? body.addressID : null,
            pickupTimeID: body.pickupTimeID,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_description == null) {
            return await res.data;
          } else {
            // this.AlertError(res.data.error_description);
            return res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });

      //   let data = []
      //  let bodyout = []
      //  bodyout.orderMasterID = body.orderMasterID,
      //  bodyout.addressID = body.addressID != undefined ? body.addressID : null ,
      //  bodyout.pickupTimeID = body.pickupTimeID
      //   data.status = "successs"
      //   return data
    },
    async RTS_lazada_package(body) {
      return await axios
        .post(
          ecomService_dotnet + "Order/rts-lazada-order",
          {
            packageID: body.packageID,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_description == null) {
            return await res.data;
          } else {
            // this.AlertError(res.data.error_description);
            return res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
      //  let data = []
      //  let bodyout = []
      //  bodyout.orderMasterID = body.orderMasterID,
      //   data.status = "successs"
      //   return data
    },
    async RTS_tiktok_order(body) {
      return await axios
        .post(
          ecomService_dotnet + "Order/rts-tiktok-order",
          {
            packageID: body.packageID,
            handover_method: body.handover_method,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_description == null) {
            return await res.data;
          } else {
            // this.AlertError(res.data.error_description);
            return res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });

      // let data = []
      //  let bodyout = []
      //  bodyout.orderMasterID = body.orderMasterID,
      //  bodyout.handover_method = body.handover_method,
      //   data.status = "successs"
      //   return data
    },
    async preparePrintAwb(packageIDList) {
      return await axios
        .post(
          ecomService_dotnet + "Order/prepare-print-awb",
          {
            packageIDList: packageIDList,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_description == null) {
            return await res.data;
          } else {
            this.AlertError(await res.data.error_description);
            return res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async getAwb(item) {
      var isPass = false;
      while (!isPass) {
        try {
          var getAWBResponse = await axios.post(ecomService_dotnet + "Order/get-awb-by-packageid",
            {
              packageID: item.packageID,
              staffBranchID: this.staffBranchId,
              documentType: this.isNeedItemWhenPrint ? 2 : 1,
              filetype: item.referenceSourceID == 4 ? 2 : 1,
            }, { headers: this.header_token });
          if (getAWBResponse.status == 200) {
            isPass = true;
            return getAWBResponse.data;

          }
        } catch (error) {
          // do nothing
        }
      }
    },
    async createShipment(orderMasterID, packageID) {
      return await axios
        .post(
          logisticService_dotnet + "Logistic/create-shipment-V2",
          {
            orderMasterID: orderMasterID,
            packageID: packageID,
            staffBranchID: this.staffBranchId,
            isNeedAddress: true,
            isNeedProductList: this.isNeedItemWhenPrint,
            isForceNewTrack: false,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (
            res.data.status == true &&
            (res.data.message == null || res.data.message == "")
          ) {
            return await res.data;
          } else {
            // this.AlertError(res.data.message);
            return res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async prepareBeforeAllocate(packageIDList) {
      return await axios
        .post(
          orderService_dotnet + "Package/prepare-before-allocate",
          {
            packageIDList: packageIDList,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_description == null) {
            return await res.data;
          } else {
            this.AlertError(await res.data.error_description);
            return res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },
    async allocateStock(packageID, allocateMode) {
      return await axios
        .post(
          orderService_dotnet + "Package/allocate-stock",
          {
            packageID: packageID,
            allocateMode: allocateMode,
            staffBranchID: parseInt(this.staffBranchId),
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
          if (res.data.status == "success" && res.data.error_message == null) {
            return await res.data;
          } else {
            // this.AlertError(res.data.error_message);
            return res.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return null;
        });
    },

    //ส่วนของ btn กดแล้วทำงาน

    async btnSearchOrder() {
      // this.loading = true;
      this.offsets = 0;
      this.advanceSearchDialog=false;
      this.resetSearchOrder(true);

      var keyword = this.search.keyword;
      for (var item in this.search.select_header) {
        if (this.search.select_header[item].value == this.search.select_topic.value) {
          keyword[this.search.select_topic.value] = this.search.text_topic;
        } else {
          keyword[this.search.select_header[item].value] = "";
        }
      }
      if(this.CFDateFromSelected)
        keyword.confirmDateFrom = this.CFDateFromSelected;
      else
        keyword.confirmDateFrom =null;

      if(this.CFDateToSelected)
        keyword.confirmDateTo = this.CFDateToSelected;
      else
        keyword.confirmDateTo =null;

      if(this.ReadyPackDateFromSelected)
        keyword.ReadyDateFrom = this.ReadyPackDateFromSelected;
      else
        keyword.ReadyDateFrom =null;

      if(this.ReadyPackDateToSelected)
        keyword.ReadyDateTo = this.ReadyPackDateToSelected;
      else
        keyword.ReadyDateTo =null;

      if(this.DueDeliveryDateFromSelected)
        keyword.DueDateFrom = this.DueDeliveryDateFromSelected;
      else
        keyword.DueDateFrom =null;

      if(this.DueDeliveryDateToSelected)
        keyword.DueDateTo = this.DueDeliveryDateToSelected;
      else
        keyword.DueDateTo =null;

        
        
      if(this.selectedTab==130){

        keyword.shopID = this.search.shopDefault.shopID;
      }else{
        keyword.shopID = this.search.shop.shopID;
      }
     
      this.search.keyword = keyword;
      this.sortedPackageIDList=[];
      await this.spliceSearchOrder(this.offsets, this.limits);
      this.loading = false;
    },
    async btnChannelSource() {
      this.checkLatestLimitType(); //เช็คlimits เพื่อเปลี่ยนการดึงข้อมูล โดยที่  latestLimitType คือ เคยกดกรองข้อมูลมา
      this.loading = true;
      try {
        this.page = 1;
        this.dataTable.items = [];
        this.dataTable.selected = [];
        this.filteOrderDataSet.filteOrderList = [];
        this.offsets = 0;
        var keyword = this.search.keyword;
        keyword.channelID = this.search.channelSource.channelID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        this.search.keyword = keyword;
        this.sortedPackageIDList=[];
        let body = await this.spliceSearchOrder(this.offsets, this.limits);
      } catch (error) {
        this.loading = false;
        Swal.fire({
          icon: "warning",
          title: "ไม่สามารถทำรายการได้ กรุณาทำรายการอีกครั้ง",
          showConfirmButton: true,
          confirmButtonText: "OK",
        });
      }
      this.loading = false;
    },
    async btnSearchShop() {
      this.checkLatestLimitType(); //เช็คlimits เพื่อเปลี่ยนการดึงข้อมูล โดยที่  latestLimitType คือ เคยกดกรองข้อมูลมา
      if(this.search.shop==null){
           
          return;
        }
      this.loading = true;
      try {
        this.page = 1;
        this.dataTable.items = [];
        this.dataTable.selected = [];
        this.filteOrderDataSet.filteOrderList = [];
        var shop = this.search.shop;
        var keyword = this.search.keyword;
        if(shop==null){
          this.loading = false;
          return;
        }
        keyword.shopID = shop.shopID;
        keyword.expressCompanyID = null;
        keyword.printType = null;
        keyword.channelID = null;
        keyword.isSelectAll = false;
        this.search.keyword = keyword;
        this.sortedPackageIDList=[];
        let body = await this.spliceSearchOrder(this.offsets, this.limits);
      } catch (error) {
        console.log(error);
        
        this.loading = false;
        Swal.fire({
          icon: "warning",
          title: "ไม่สามารถทำรายการได้ กรุณาทำรายการอีกครั้ง",
          showConfirmButton: true,
          confirmButtonText: "OK",
        });
      }
      this.loading = false;
    },
    async btnExExprees(items) {
      this.checkLatestLimitType(); //เช็คlimits เพื่อเปลี่ยนการดึงข้อมูล โดยที่  latestLimitType คือ เคยกดกรองข้อมูลมา

      this.loading = true;
      try {
        this.page = 1;
        this.dataTable.items = [];
        this.dataTable.selected = [];
        this.filteOrderDataSet.filteOrderList = [];
        var keyword = this.search.keyword;
        keyword.expressCompanyID = items != undefined ? items : null;
        keyword.printType = null;
        keyword.isSelectAll = false;
        this.search.keyword = keyword;
        this.sortedPackageIDList=[];
        await this.spliceSearchOrder(this.offsets, this.limits);
      } catch (error) {
        this.loading = false;
        Swal.fire({
          icon: "warning",
          title: "ไม่สามารถทำรายการได้ กรุณาทำรายการอีกครั้ง",
          showConfirmButton: true,
          confirmButtonText: "OK",
        });
      }
      this.loading = false;
    },
    btnOpenSortDialog() {
      this.sortDialog = true;
    },
    btnOpenAdvanceSearchDialog() {
      this.advanceSearchDialog = true;
    },
    async btnSelectedTarget() {
      this.checkLatestLimitType(); //เช็คlimits เพื่อเปลี่ยนการดึงข้อมูล โดยที่  latestLimitType คือ เคยกดกรองข้อมูลมา

      // this.loading = true;
      this.dataTable.selected = [];
      this.filteOrderDataSet.filteOrderList = [];
      var keyword = this.search.keyword;
      keyword.isSelectAll = true;
      this.search.keyword = keyword;
      this.sortedPackageIDList=[];
      let body = await this.SearchOrder(0, this.total);
      console.log(body);
      
      for (var item in body.packageIDList) {
        this.dataTable.selected.push({
          orderMasterPackageInfoID: body.packageIDList[item],
        });
      }
      if (body.packageIDList != null) {
        this.sortedPackageIDList = body.packageIDList;
      }
      // this.loading = false;
    },
    async ExportExcel(){

      this.loadingExcel=1;
      var keyword = this.search.keyword;
      keyword.isSelectAll = false;
      this.search.keyword = keyword;
      var searchResponse  = await axios .post(orderService_dotnet + "Package/search-package-packing-list",
          {
            warehouseIDList: this.warehouseID_selected,
            skip: 0,
            take: 10, 
            keyword: this.search.keyword,  
            orderBy: this.orderbyText == '' ? null : this.orderbyText
          },  { headers: this.header_token } );
      
      let limits=searchResponse.data.resultCount;
      let take=100;

      keyword = this.search.keyword;
      keyword.isSelectAll = true;
      this.search.keyword = keyword;
      let body =  await axios .post(orderService_dotnet + "Package/search-package-packing-list",
                  {
                    warehouseIDList: this.warehouseID_selected,
                    skip: 0,
                    take: limits, 
                    keyword: this.search.keyword,  
                    orderBy: this.orderbyText == '' ? null : this.orderbyText
                  },  { headers: this.header_token } );
    
      let packageid = body.data.packageIDList;

      let round =Math.ceil( packageid.length / take);
      var ExcelHeader = this.OrderExportExcelHeader.map(function (item) { return item.label; });
      if(this.selectedTab==20){
        ExcelHeader.push("ที่จองสต๊อกได้");
        ExcelHeader.push("ที่ต้องจัดหาสต๊อกเพิ่มเติม");
      }
      let rowData=[];
      let datasetExportExcel=[];
      let productList = []; 
      var stockshopidlist = []
      var TmpDataResponse = [];
      for (let index = 0; index < round; index++) 
      {
        let temppackage = packageid.splice(0,take).join(',')
        var orderExcelResponse=  await axios.post(orderService_dotnet + "Order/search-order-list-for-warehouse",
                    {
                      branchId: this.BranchID,
                      isExcel: true,
                      isFulfillSecondRow: this.isFulfillSecondRow,
                      isNeedItem: this.isNeedItemWhenExport,
                      warehouseIDList:this.warehouseID_selected,
                      packageID:temppackage,
                      skip:0,
                      take:100
                    },  { headers: this.header_token } );
      
                    
      
        for (let j of orderExcelResponse.data.data) {
          TmpDataResponse.push(j);
          // rowData = [];
          // this.OrderExportExcelHeader.forEach(excelHeaderName => {
          //   rowData.push(j[excelHeaderName.field]);
          // });
          // datasetExportExcel.push(rowData);

          if(this.isNeedItemWhenExport){
            let product = productList.find(item=>item.stockShopID == j.stockShopID)
            if(product==null){
              stockshopidlist.push(j.stockShopID);
              let productdata = {
                name:j.productName,
                prop1Description:j.prop1Description,
                prop2Description:j.prop2Description,
                barcode:j.barcode,
                sku:j.sku,
                stockShopID:j.stockShopID,
                productcode:j.productCode,
                quantity:j.quantity,
              }
              productList.push(productdata);
            }else{
              product.quantity=product.quantity+j.quantity;
            }
          }
        
        }
      
      

  
        this.loadingExcel = parseInt(
          (parseFloat(index+2) / parseFloat(round+1)) * parseFloat(100)
        );
      }
      
   
        
      if(this.selectedTab==20&& stockshopidlist.length>0)
      {
          var stockResponse=  await axios.post(stockService_dotnet + "Stock/get-stock-list-by-stockshopid-list",
                    {
                      stockShopIDList:  stockshopidlist
                    },  { headers: this.header_token } );
          productList.forEach(element => {
            var thistock = stockResponse.data.find(i=>i.id==element.stockShopID)
            
           
            
            let left = thistock.quantity - thistock.allocatedQuantity;
            if(left<0){
              element.allocated=0;
            }
            else if(left<=element.quantity){
              element.allocated =left
            }
            else if(left>element.quantity){
              element.allocated =element.quantity
            }else{
              element.allocated=0;
            }
            element.toAllocate = element.quantity-element.allocated;
            element.leftAllocate = element.allocated;
          
          });
        }

      for (let j of TmpDataResponse) {
        rowData = [];
        this.OrderExportExcelHeader.forEach(excelHeaderName => {
          rowData.push(j[excelHeaderName.field]);
        });
        
        if(this.selectedTab==20&& stockshopidlist.length>0){
          let product = productList.find(item=>item.stockShopID == j.stockShopID);
          if(product.toAllocate==0){
            j.allocated=j.quantity;
            j.toAllocate=0;
          }
          else{
            if(product.leftAllocate==0){
              j.allocated=0;
              j.toAllocate=j.quantity;
            }
            else if(product.leftAllocate>=j.quantity)
            {
              j.allocated=j.quantity;
              j.toAllocate=0;
              product.leftAllocate -= j.quantity
            }
            else
            {
              j.allocated=product.leftAllocate;
              j.toAllocate= j.quantity - j.allocated;
              product.leftAllocate=0;
            }
          }
          rowData.push(j.allocated);
          rowData.push(j.toAllocate);
        }
        datasetExportExcel.push(rowData);
      }
  
      let excelproductdata=[]
      productList.forEach(element => {
        let rowproduct=[];
        rowproduct.push(element.name)
        rowproduct.push(element.prop1Description)
        rowproduct.push(element.prop2Description)
        rowproduct.push(element.productcode)
        rowproduct.push(element.sku)
        rowproduct.push(element.barcode)
        rowproduct.push(element.quantity) 
        if(this.selectedTab==20){
          rowproduct.push(element.allocated) ;
          rowproduct.push(element.toAllocate) ;
        }
        excelproductdata.push(rowproduct)
      });
     
     
      this.loadingExcel = datasetExportExcel.length == 0 ? 0 : 100;
      const ws1 = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws1, [ExcelHeader]);
      utils.sheet_add_json(ws1, datasetExportExcel, { origin: 'A2', skipHeader: true });

      const ws2 = utils.json_to_sheet([]);
      if(this.selectedTab==20)
      {
        utils.sheet_add_aoa(ws2, [['สินค้า','ลักษณะ1','ลักษณะ2','รหัสสินค้า','sku','barcode','จำนวน','ที่สามารถจองสต๊อกได้','ที่ต้องจัดหาสต๊อกเพิ่ม']]);
      }
      else
      {
        utils.sheet_add_aoa(ws2, [['สินค้า','ลักษณะ1','ลักษณะ2','รหัสสินค้า','sku','barcode','จำนวน']]);
      }
     
      utils.sheet_add_json(ws2, excelproductdata, { origin: 'A2', skipHeader: true });

      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws1, "รายการออเดอร์");
      utils.book_append_sheet(wb, ws2, "รายการสินค้า");

      const d = new Date();
      let dateExported = d.getFullYear().toString() + (d.getMonth() + 1).toString() + d.getDate().toString() + d.getHours().toString() + d.getMinutes().toString() + d.getSeconds().toString();
      writeFileXLSX(wb, 'รายงานข้อมูลออเดอร์_' + dateExported + ".xlsx");
       
    },
    async btnSearchPrintType(item) {
      this.checkLatestLimitType(); //เช็คlimits เพื่อเปลี่ยนการดึงข้อมูล โดยที่  latestLimitType คือ เคยกดกรองข้อมูลมา

      this.loading = true;
      try {
        this.page = 1;
        this.dataTable.items = [];
        this.dataTable.selected = [];
        var keyword = this.search.keyword;
        keyword.printType = item.id;
        keyword.isSelectAll = false;
        this.search.keyword = keyword;
        this.sortedPackageIDList=[];
        let body = await this.spliceSearchOrder(this.offsets, this.limits);
        this.search.groupPrint = item;
      } catch (error) {
        this.loading = false;
        Swal.fire({
          icon: "warning",
          title: "ไม่สามารถทำรายการได้ กรุณาทำรายการอีกครั้ง",
          showConfirmButton: true,
          confirmButtonText: "OK",
        });
      }
      this.loading = false;
    },
    async btnSearchALL(item) {
      this.checkLatestLimitType(); //เช็คlimits เพื่อเปลี่ยนการดึงข้อมูล โดยที่  latestLimitType คือ เคยกดกรองข้อมูลมา

      // this.loading = true;
      this.dataTable.selected = [];
      var keyword = this.search.keyword;
      keyword.printType = item.id;
      keyword.isSelectAll = false;
      this.search.keyword = keyword;
      this.sortedPackageIDList=[];
      let body = await this.spliceSearchOrder(this.offsets, this.limits);
      this.search.groupPrint = item;
      // this.loading = false;
    },
    async btnPrintBarcode() {
      if (this.dataTable.selected.length > 0) {
        // this.loading = true;
        let selectedBody = [...this.dataTable.selected];
        const GenToURL = selectedBody.map((item) => item.orderMasterPackageInfoID);
        // .map((id) => Number(id));
        let query = JSON.stringify(GenToURL);
        let encodedQuery = encodeURIComponent(query);

        var key = Math.floor(Math.random() * 1000000 + 1).toString();
        sessionStorage.setItem("packageIDList", encodedQuery);
        sessionStorage.setItem("encodedQueryKey", key);
        window.open(`/printPackingList?orderID=${key}`);

        this.loading = false;
      }
    },
    async btnFilterOrder() {
      this.filteOrderDataSet.tab = 0;
      this.filteOrderDataSet.sku_start = 1;
      this.filteOrderDataSet.sku_end = 1;
      this.filteOrderDataSet.item_start = 1;
      this.filteOrderDataSet.item_end = 1;
      this.filteOrderDataSet.item_end = 1;
      this.filteOrderDataSet.radio_item = 0;
      this.filteOrderDataSet.radio_sku = 0;
      this.filteOrderDataSet.productList = [];
      this.filteOrderDataSet.dataset = [];
      this.filteOrderDataSet.mapProductList = [];
      this.filteOrderDataSet.mapSKUList = [];

      if (this.dataTable.selected.length > 0) {
        // this.loading = true;
        this.progressBarActive=true;
        let selectedBody = [...this.dataTable.selected];
        const packageIDList = selectedBody
          .map((item) => item.orderMasterPackageInfoID)
          .map((orderMasterPackageInfoID) => Number(orderMasterPackageInfoID));

        let amount_of_data_each = 50; // จำนวนข้อมูลที่มีในแต่ละก้อน
        let countObject = packageIDList.length;
        let loopCountFetch = Math.ceil(countObject / amount_of_data_each); // จำนวนที่ต้องวนข้อมูล
        let bodyOut = [];
        let objList = [];
        if (this.filteOrderDataSet.filteOrderList.length > 0) {
          const packageIDArray = Object.values(packageIDList);
          this.filteOrderDataSet.filteOrderList.forEach((packageIDList) => {
            const filterSelected = packageIDArray.find(
              (item) => item == packageIDList.packageID
            );
            if (filterSelected) {
              objList.push(packageIDList);
            }
          });
        } else {
          for (let i = 0; i < loopCountFetch; i++) {
            bodyOut[i] = packageIDList.splice(0, amount_of_data_each);
            var data_obj = await this.getFilterOrder(bodyOut[i]);
            await this.getPercentLoading(i + 1, loopCountFetch);
            if (data_obj != null) {
              objList.push(...data_obj);
            }
          }
        }
        this.progressBarActive=false;
        // เช็คว่ามี SKU ซ้ำไหม
        var indexCount = 1;
        const newData = objList.reduce((acc, curr) => {
          const found = acc.find((item) => item.stockShopID === curr.stockShopID);
          if (found) {
            found.orderCount += 1;
            found.quantityToPack += curr.quantityToPack;
            found.outStock -= curr.quantityToPack;
            found.outStockShow = found.outStock < 0 ? found.outStock : "";
          } else {
            curr.orderCount = 1;
            curr.outStock = curr.currentQuantity - curr.quantityToPack;
            curr.outStockShow = curr.outStock < 0 ? curr.outStock : "";
            curr.no = indexCount;
            acc.push({ ...curr });
            indexCount = indexCount + 1;
          }
          return acc;
        }, []);
        // map ข้อมูล ที่ orderMasterID เหมือนกันอยู่ใน arry เดียวกัน
        const mapData = [];



        objList.forEach((order) => {
          const existingOrder = mapData.find(
            (item) => item.packageID === order.packageID
          );
          if (existingOrder) {
            existingOrder.item.push(order);
            existingOrder.itemCount = existingOrder.itemCount + order.quantityToPack;
            existingOrder.skuCount += 1;
          } else {
            mapData.push({
              orderMasterID: order.orderMasterID,
              item: [order],
              itemCount: order.quantityToPack,
              printpackinglistTime: order.printpackinglistTime,
              skuCount: 1,
              packageID: order.packageID,
            });
          }
        }); 
        const mapSkuCount = [];
        const mapProductCount = [];
        //จัดกลุ่มตามจำนวน SKU เท่ากัน
        const skuCountsGroup = [...new Set(mapData.map((item) => item.skuCount))].sort(
          (a, b) => a - b
        );
        const productCountsGroup = [...new Set(mapData.map((item) => item.itemCount))].sort(
          (a, b) => a - b
        );

        //วนข้อมูลเพื่อดึงมา
        skuCountsGroup.forEach((skuCount) => {
          //กรองข้อมูลที่มาค่าเท่ากัน
          const filteredData = mapData.filter((item) => item.skuCount === skuCount);

          const orderCount = filteredData.length;
          const printY = filteredData.filter((item) => item.printpackinglistTime !== null)
            .length;
          const printN = filteredData.filter((item) => item.printpackinglistTime === null)
            .length;

          mapSkuCount.push({
            orderCount,
            PrintY: printY,
            PrintN: printN,
            skuCount,
          });
        });
       

        productCountsGroup.forEach((itemCount) => {
          //กรองข้อมูลที่มาค่าเท่ากัน
          const filteredData = mapData.filter((item) => item.itemCount === itemCount);

          const orderCount = filteredData.length;
          const printY = filteredData.filter((item) => item.printpackinglistTime !== null)
            .length;
          const printN = filteredData.filter((item) => item.printpackinglistTime === null)
            .length;

          mapProductCount.push({
            orderCount,
            PrintY: printY,
            PrintN: printN,
            itemCount,
          });
        });
    
        this.filteOrderDataSet.filteOrderList = objList; // ไว้สำหรับแสดง จำนวน SKU ในออเดอร์
        this.filteOrderDataSet.mapSKUList = mapSkuCount; // ไว้สำหรับแสดง จำนวน SKU ในออเดอร์
        this.filteOrderDataSet.mapProductList = mapProductCount; // ไว้สำหรับแสดง จำนวน SKU ในออเดอร์
        this.filteOrderDataSet.productList = mapData; // ไว้สำหรับค้นหาต้อนใส่ข้อมูล
        this.filteOrderDataSet.dataset = newData; //  ไว้แสดงข้อมูล
        this.filteOrderDataSet.selected = newData; // ไว้เลิอกในการแสดง
   

        // this.loading = false;
        this.filterOrderPopup.dialog = true;
      }
    },
    CheckFilter() {
      this.orderbyText = ''
      

      if (this.sortAbleSelected1) {
        this.orderbyText += ("," + this.sortAbleSelected1 + ":" + this.sortTypeSelected1)
      }
      if (this.sortAbleSelected2) {
        this.orderbyText += ("," + this.sortAbleSelected2 + ":" + this.sortTypeSelected2)
      }
      if (this.sortAbleSelected3) {
        this.orderbyText += ("," + this.sortAbleSelected3 + ":" + this.sortTypeSelected3)
      }
      if (this.sortAbleSelected4) {
        this.orderbyText += ("," + this.sortAbleSelected4 + ":" + this.sortTypeSelected4)
      }
      if (this.sortAbleSelected5) {
        this.orderbyText += ("," + this.sortAbleSelected5 + ":" + this.sortTypeSelected5)
      }
      if (this.sortAbleSelected6) {
        this.orderbyText += ("," + this.sortAbleSelected6 + ":" + this.sortTypeSelected6)
      }
      if (this.orderbyText != '') {
        this.orderbyText = this.orderbyText.substring(1);
      }
      this.UpdateViewTypePage("product-pack-list", this.orderbyText);
      this.sortDialog = false;
      this.btnSearchOrder();
    },
    async btnSumitFilteOrder() {
      // this.loading = true;
      var clone = [...this.filteOrderDataSet.selected];

      var data = [...this.filteOrderDataSet.dataset];

      var dataList = [...this.filteOrderDataSet.productList];

      // กรองข้อมูลที่มี stockShopID ไม่ซ้ำกัน  ระหว่าง clone กับ data คือข้อมูลที่ไม่ถูกเลือกออกมา
      const filteredData = data.filter((obj1) => {
        return !clone.some((obj2) => {
          return obj1.stockShopID == obj2.stockShopID;
        });
      });

      //แปลงเป็นเลขโดยนำจากข้อมูลที่เลือก
      const itemStockShopIdSelected = clone.map((item) => item.stockShopID);
      //แปลงเป็นเลขโดยนำจากข้อมูลที่ไม่เลือก
      const itemStockShopIdNotSelected = filteredData.map((item) => item.stockShopID);
      //แปลงเป็นเลขโดยนำจากข้อมูลทั้งหมด โดยเอา packageID แต่ละ Iiem
      const itemStockPackageIDList = [
        ...new Set(dataList.flatMap((data) => data.item.map((item) => item.packageID))),
      ];

      const PackageIDList = [];
      for (let index = 0; index < dataList.length; index++) {
        //เช็คว่าต้องเช็คจากจำนวน sku มั้ย
        if (this.filteOrderDataSet.radio_sku) {
          if (
            dataList[index].skuCount >= this.filteOrderDataSet.sku_start &&
            dataList[index].skuCount <= this.filteOrderDataSet.sku_end
          ) {
            //ไม่ต้องทำอะไร
          } else {
            //ถ้าจำนวน sku ไม่อยู๋ใน range ที่เลือก ให้ข้ามไปเลย
            continue;
          }
        }

        //เช็คว่าต้องเช็คจากจำนวน สินค้า มั้ย
        if (this.filteOrderDataSet.radio_item) {
          if (
            dataList[index].itemCount >= this.filteOrderDataSet.item_start &&
            dataList[index].itemCount <= this.filteOrderDataSet.item_end
          ) {
            //ไม่ต้องทำอะไร
          } else {
            //ถ้าจำนวนสินค้าไม่อยู๋ใน range ที่เลือก ให้ข้ามไปเลย
            continue;
          }
        }
        if (itemStockShopIdNotSelected.length > 0) {
          const existingOrder = dataList[index].item.find((item) =>
            itemStockShopIdNotSelected.includes(item.stockShopID)
          );
          if (existingOrder) {
            continue;
          }
        }
        PackageIDList.push(dataList[index].packageID);
      }

      var keyword = this.search.keyword;
      keyword.isSelectAll = false;
      this.search.keyword = keyword;

      let amount_of_data_each = 50; // จำนวนข้อมูลที่มีในแต่ละก้อน
      let TotalRoundToFetchData = Math.ceil(PackageIDList.length / amount_of_data_each); // จำนวนที่ต้องวนข้อมูล
      let objList = [];

      if (this.latestLimitType == 2) {
        this.dataTable.items.forEach((order) => {
          const filterSelected = PackageIDList.find(
            (item) => item == order.orderMasterPackageInfoID
          );
          if (filterSelected) {
            objList.push(order);
          }
        });
      } else {
        this.latestLimitType = 2;
        var indexNo = 1;
        try {
          this.loading = true;
          this.sortedPackageIDList=[];
          this.progressBarActive=true;
          for (let i = 0; i < TotalRoundToFetchData; i++) {
            var TempPackageIDList = PackageIDList.splice(0, amount_of_data_each);
            
            let data_obj = await this.SearchOrder(
              0,
              amount_of_data_each,
              TempPackageIDList
            );
            for (let index = 0; index < data_obj.resultData.length; index++) {
              data_obj.resultData[index].no = indexNo;
              indexNo = indexNo + 1;
            }
            await this.getPercentLoading(i + 1, TotalRoundToFetchData);
            objList.push(...data_obj.resultData);
          }
          this.progressBarActive=false;
        } catch (error) {
          this.loading = false;
          Swal.fire({
            icon: "warning",
            title: "ไม่สามารถทำรายการได้ กรุณาทำรายการอีกครั้ง",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          return;
        }
      }

      this.limits = 999999;
      this.dataTable.items = objList;
      this.total = objList.resultCount;
      this.pageLength = 1;
      this.dataTable.selected = [];
      this.filteOrderDataSet.dataset = [];
      this.loading = false;
      this.filterOrderPopup.dialog = false;
    },
    async btnChangeLimits() {
      this.dataTable.items = [];
      this.dataTable.selected = [];
      //เงื่อนไขไม่ยิงเมื่อมีการเปิดหน้า popup ตัวกรอง
      if (this.latestLimitType != 2) {
        // this.loading = true;
        this.sortedPackageIDList=[];
        await this.spliceSearchOrder(this.offsets, this.limits);
        await this.getCountOrderPackingList();
      }
      // this.loading = false;
    },
    async btnExportExcelFilteOrder() {
      return this.filteOrderDataSet.headerExcel;
    },
    async btnExportExcel() {
      const OrderID = this.dataTable.selected
        .map((item) => item.id)
        .map((id) => Number(id));

      let amount_of_data_each = 50; // จำนวนข้อมูลที่มีในแต่ละก้อน
      let countObject = OrderID.length;
      let loopCountFetch = Math.ceil(countObject / amount_of_data_each); // จำนวนที่ต้องวนข้อมูล
      let bodyOut = [];
      let objList = [];
      this.progressBarActive=true;
      if (OrderID.length > 50) {
        for (let i = 0; i < loopCountFetch; i++) {
          this.search.keyword.isSelectAll = false;
          // this.loading = true;
          bodyOut[i] = OrderID.splice(0, amount_of_data_each);
          let data_obj = await this.SearchOrder(0, 50, bodyOut[i], true);
          await this.getPercentLoading(i + 1, loopCountFetch);
          objList.push(...data_obj.resultData);
        }
      } else {
        await this.getPercentLoading(1, 2);
        let res = await this.SearchOrder(0, OrderID.length, OrderID, true);
        objList = res.resultData;
        await this.getPercentLoading(2, 2);
      }
      this.progressBarActive=false;
      this.dataTable.itemsExcel = await objList;

      //ที่ใส่ไว้เพราะ  ข้อมูลยังเปลี่ยนแปลงไม่เสร็จทำงานไม่สำเร็จ
      const interval = setInterval(() => {
        if (this.dataTable.itemsExcel) {
          clearInterval(interval);
          document.getElementById("GetExcel").click();
        }
      }, 100);
    },
    async btnMappedProduct() {
      const packageIDList = this.dataTable.selected.map(
        (item) => item.orderMasterPackageInfoID
      );
      var dataMap = await this.getMappedProduct(packageIDList);
      if (dataMap != null) {
        this.MappedProductsDataSet.productListToMapDataSet = dataMap.productListToMap;
        this.MappedProductsDataSet.productListNotMapDataSet =
          dataMap.productListNotMapped;
        this.addStockPopup.shopID = dataMap.shopID;
        this.addStockPopup.warehouseID = dataMap.warehouseID;
        this.MappedProductsPopup.dialog = true;
        this.MappedProductsDataSet.selectedToMap = [];
        this.MappedProductsDataSet.productLisDataSet = [];
      }
    },
    async btnMapDataSet(index, type) {
      // type 1 จาก Map TO  NotMap
      //      2 จาก NotMap To Map
      var obj = [];
      obj.description = type == 1 ? "ไม่ผูกสินค้า" : "ย้อนกลับ";
      var check = await this.alertConfirm(obj);

      if (check) {
        this.AlertSuccess();
        this.MappedProductsDataSet.selectedToMap = [];
        this.MappedProductsDataSet.productLisDataSet = [];
        if (type == 1) {
          this.MappedProductsDataSet.productListNotMapDataSet.unshift(
            ...this.MappedProductsDataSet.productListToMapDataSet.splice(index, 1)
          );
        } else {
          this.MappedProductsDataSet.productListToMapDataSet.unshift(
            ...this.MappedProductsDataSet.productListNotMapDataSet.splice(index, 1)
          );
        }
      }
    },
    async btnSearchProductItem(itemData) {
      if (itemData.productMapped != null) {
        const productMasterItemIDList = itemData.productMapped
          .map((item) => item.productMasterItemID)
          .map((productMasterItemID) => Number(productMasterItemID));
        this.addStockPopup.productIdList = productMasterItemIDList;
      } else {
        this.addStockPopup.productIdList = [];
      }

      var dataSetAdd = await this.getProductItemList(
        this.addStockPopup.limits,
        this.addStockPopup.offsets,
        this.addStockPopup.shopID
      );
      this.addStockPopup.dataset = dataSetAdd.productList;
      this.addStockPopup.dialog = true;
      this.addStockPopup.page = 1;
    },
    async btnPopupsearchStock(limits, offsets) {
      var dataSetAdd = [];
      this.addStockPopup.selectProductList = [];
      this.addStockPopup.productIdList = [];

      if (this.addStockPopup.searchStockModelPopup != null) {
        this.addStockPopup.popupsearch = this.addStockPopup.searchStockModelPopup.trim();
        if (this.addStockPopup.popupsearch.length > 0) {
          dataSetAdd = await this.getProductItemList(
            limits,
            offsets,
            this.addStockPopup.shopID,
            this.addStockPopup.popupsearch
          );
          this.addStockPopup.dataset = dataSetAdd.productList;
        } else {
          dataSetAdd = await this.getProductItemList(
            limits,
            offsets,
            this.addStockPopup.shopID,
            null
          );
          this.addStockPopup.page = 1;
          this.addStockPopup.dataset = dataSetAdd.productList;
        }
      }
    },
    async btnProductSelectCheckbox(item, index) {
      // เปลี่ยนสถานะ selectProduct เมื่อปุ่มถูกคลิก
      this.$set(item, "selectProduct", !item.selectProduct);

      if (item.selectProduct) {
        // ถ้า selectProduct เป็น true ให้นำเข้า selectProductList
        this.addStockPopup.selectProductList.unshift(item);
      } else {
        // ถ้า selectProduct เป็น false ให้นำออก selectProductList
        this.$delete(this.addStockPopup.selectProductList, index);
      }
    },
    async btnAddStockPopup() {
      let foundProduct = this.MappedProductsDataSet.productListToMapDataSet.find(
        (product) => product.name == this.MappedProductsDataSet.selectedToMap[0].name
      );

      this.addStockPopup.selectProductList.forEach((item) => {
        this.addStockPopup.productIdList.push(item.id);
        item.quantity != null ? item.quantity : (item.quantity = 1);
      });

      if (foundProduct) {
        foundProduct.productMapped =
          foundProduct.productMapped === null ? [] : foundProduct.productMapped;
        foundProduct.productMapped.push(...this.addStockPopup.selectProductList);
        this.MappedProductsDataSet.productLisDataSet = await foundProduct.productMapped;
        this.addStockPopup.selectProductList = [];
      }
      this.addStockPopup.dialog = false;
    },
    async btnAddMppDelete(index, item) {
      var obj = [];
      obj.description = "ค้องการลบจริงหรือไม่";

      var check = await this.alertConfirm(obj);

      if (check) {
        let foundProduct = this.MappedProductsDataSet.productListToMapDataSet.find(
          (product) => product.name == this.MappedProductsDataSet.selectedToMap[0].name
        );
        if (foundProduct) {
          foundProduct.productMapped.splice(index, 1);
          this.addStockPopup.productIdList.splice(index, 1);
          this.rowClick(foundProduct);
        }
      }
    },
    async btnSubmitTieMap() {
      this.MappedProductsDataSet.loading = true;

      let Round = 0;
      let countLoop = 0;

      const uniquetoPackageIDs = [
        ...new Set(
          this.MappedProductsDataSet.productListToMapDataSet.flatMap(
            (item) => item.packageIDList
          )
        ),
      ];
      const uniqueNotPackageIDs = [
        ...new Set(
          this.MappedProductsDataSet.productListNotMapDataSet.flatMap(
            (item) => item.packageIDList
          )
        ),
      ];
      const uniqueValuesSet = new Set([...uniquetoPackageIDs, ...uniqueNotPackageIDs]);
      const uniqueValuesArray = Array.from(uniqueValuesSet);
      countLoop =
        this.MappedProductsDataSet.productListToMapDataSet.length +
        this.MappedProductsDataSet.productListNotMapDataSet.length +
        uniqueValuesArray.length;
        this.progressBarActive=true;
      await this.getPercentLoading(Round, countLoop);

      for (const item of this.MappedProductsDataSet.productListToMapDataSet) {
        Round++;
        item.staffBranchID = this.staffBranchId;
        this.getPercentLoading(Round, countLoop);
        await this.saveToMapProduct(item);
      }

      // ประมวลผล this.MappedProductsDataSet.productListNotMapDataSet
      for (const item of this.MappedProductsDataSet.productListNotMapDataSet) {
        Round++;
        item.staffBranchID = this.staffBranchId;
        this.getPercentLoading(Round, countLoop);
        await this.saveNotMapProduct(item);
      }

      // ประมวลผล uniqueValuesArray
      for (const item of uniqueValuesArray) {
        Round++;
        let body = [];
        body.packageID = item;
        body.staffBranchID = this.staffBranchId;
        this.getPercentLoading(Round, countLoop);
        await this.processOrderAfterMap(body);
      }
      this.progressBarActive=false;
      Swal.fire({
        title: "ผูกรายการสินค้าสำเร็จ",

        icon: "success",
      });
      // this.AlertSuccess("ผูกรายการสินค้าสำเร็จ");
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    async btnViewReport() {
      const packageIDList = this.dataTable.selected.map(
        (item) => item.orderMasterPackageInfoID
      );
      let body = [];
      this.reportOrderDataSet.header = [...this.reportOrderDataSet.headerIN];
      var headerItem = this.reportOrderDataSet.header.find(
        (item) => item.text === "ชื่อ"
      );

      if (this.selectedTab == 10) {
        headerItem.value = "name";
        headerItem.field = "name";
        body = await this.getAllReportMapProduct(packageIDList);
        if (body.length > 0) {
          this.reportOrderDataSet.header = this.reportOrderDataSet.header.filter((item) =>
            Object.keys(body.productListToMap[0]).includes(item.value)
          );
          this.reportOrderDataSet.dataset = await body.productListToMap;
        }
      } else {
        let bodyin = [];
        headerItem.value = "productName";
        headerItem.field = "productName";
        bodyin.packageIDList = packageIDList;
        body = await this.getAllViewReportPack(bodyin);
        this.reportOrderDataSet.header = this.reportOrderDataSet.header.filter((item) =>
          Object.keys(body.productList[0]).includes(item.value)
        );
        this.reportOrderDataSet.dataset = await body.productList;
      }

      this.reportOrderPopup.dialog = true;
    },
    async btnPackageChangeStatus() {
      const packageIDList = this.dataTable.selected.map(
        (item) => item.orderMasterPackageInfoID
      );

      let data = await this.getPreparePackageBeforeChangeStatus(packageIDList);
      this.changeStatusDataSet.dataset = data.packageList;
      this.changeStatusDataSet.changeStatus = null;
      this.changeStatusDataSet.success = 0;
      this.changeStatusDataSet.error = 0;
      this.changeStatusDataSet.datasetError = [];
      this.changeStatusPopup.tab = 0;
      this.changeStatusPopup.dialog = true;
    },
    async btnSubmitChamgeStatus() {
      this.changeStatusDataSet.loadingPercent = true;
      this.changeStatusDataSet.loading = true;

      let Round = 0;
      let countLoop = this.changeStatusDataSet.dataset.length;
      this.progressBarActive=true;
      for (const item of this.changeStatusDataSet.dataset) {
        Round++;
        this.getPercentLoading(Round, countLoop);
        let data = await this.changePackageStatus(
          item.packageID,
          this.changeStatusDataSet.changeStatus.ID
        );

        if (data.status == "success") {
          this.$set(item, "processing", data.status);
          this.changeStatusDataSet.success++;
        } else {
          this.$set(item, "processing", "error");
          this.$set(item, "error_description", data.error_description);
          this.changeStatusDataSet.datasetError.push(item);
          this.changeStatusDataSet.error++;
        }
      }
      this.progressBarActive=false;
    },
    async btnPackageBackStatus() {
      const packageIDList = this.dataTable.selected.map(
        (item) => item.orderMasterPackageInfoID
      );

      let data = await this.getPreparePackageBeforeChangeStatus(packageIDList);
      this.backStatusDataSet.dataset = data.packageList;
      this.backStatusDataSet.changeStatus = null;
      this.backStatusDataSet.success = 0;
      this.backStatusDataSet.error = 0;
      this.backStatusDataSet.datasetError = [];
      this.backStatusPopup.tab = 0;
      this.backStatusPopup.dialog = true;
    },
    async btnSubmitBackStatus() {
      this.backStatusDataSet.loadingPercent = true;
      this.backStatusDataSet.loading = true;
      let Round = 0;
      let countLoop = this.backStatusDataSet.dataset.length;
      this.progressBarActive=true;
      for (const item of this.backStatusDataSet.dataset) {
        Round++;
        this.getPercentLoading(Round, countLoop);
        let data = await this.changePackageStatus(
          item.packageID,
          this.backStatusDataSet.changeStatus.ID
        );

        if (data.status == "success") {
          this.$set(item, "processing", data.status);
          this.backStatusDataSet.success++;
        } else {
          this.$set(item, "processing", "error");
          this.$set(item, "error_description", data.error_description);
          this.backStatusDataSet.datasetError.push(item);
          this.backStatusDataSet.error++;
        }
      }
      this.progressBarActive=false;
    },
    async btnUpdateToMarketplace() {
      const packageIDList = this.dataTable.selected.map(
        (item) => item.orderMasterPackageInfoID
      );

      let data = await this.prepareUpdateToMarketplace(packageIDList);

      if (data.error_description == null) {
        this.UpdateToMarketplacePopup.marketplaceName =
          (await data.marketplaceName) != null ? data.marketplaceName : null;
        this.UpdateToMarketplaceDataSet.dataset = data.orderList;
        this.UpdateToMarketplaceDataSet.datasetError = [];
        this.UpdateToMarketplaceDataSet.success = 0;
        this.UpdateToMarketplaceDataSet.error = 0;
        this.UpdateToMarketplacePopup.tab = 0;

        if (data.marketplaceName == "shopee") {
          this.UpdateToMarketplaceDataSet.shopeeType = 0;
          this.UpdateToMarketplaceDataSet.pickupTimeID = null;

          if (
            data.shopeeInitData.response.pickup != null &&
            data.shopeeInitData.response.pickup.address_list != null
          ) {
            this.UpdateToMarketplaceDataSet.pickup = await data.shopeeInitData.response
              .pickup.address_list;

            //หา ตำแหน่งที่ให้เลือกเริ่มต้น
            const index = await this.UpdateToMarketplaceDataSet.pickup.findIndex((item) =>
              item.address_flag.includes("pickup_address")
            );

            //นำค่ามาใส่ เริ่มต้น และ ใส่เวลา
            this.UpdateToMarketplaceDataSet.selectedAddress = index;
            this.UpdateToMarketplaceDataSet.selectedpickupTime = this.UpdateToMarketplaceDataSet.pickup[
              index
            ];
          }
        }
        if (data.marketplaceName == "tiktok") {
          this.UpdateToMarketplaceDataSet.tiktokType = 0;
        }
        this.UpdateToMarketplaceDataSet.loading = false;
        this.UpdateToMarketplaceDataSet.loadingPercent = false;
        this.UpdateToMarketplacePopup.dialog = true;
      }
    },
    async btnSubmitToMarketPlace(marketplaceName) {
      let Round = 0;
      this.progressBarActive=true;
      let countLoop = this.UpdateToMarketplaceDataSet.dataset.map(
        (item) => item.orderMasterID
      ).length;
      this.UpdateToMarketplaceDataSet.loading = true;
      this.UpdateToMarketplaceDataSet.loadingPercent = true;

      if (marketplaceName == "shopee") {
        for (const item of this.UpdateToMarketplaceDataSet.dataset) {
          Round++;
          let body = {
            packageID: item.packageID,
            orderMasterID: item.orderMasterID,
            addressID:
              this.UpdateToMarketplaceDataSet.pickupTimeID == null
                ? null
                : this.UpdateToMarketplaceDataSet.selectedpickupTime.address_id,
            pickupTimeID: this.UpdateToMarketplaceDataSet.pickupTimeID,
          };
          this.getPercentLoading(Round, countLoop);
          if (body.addressID != null) {
            body.addressID = body.addressID.toString();
          }
          let data = await this.RTS_shopee_package(body);
          this.$set(item, "processing", data.status);
          this.$set(item, "message", data.message[this.set_language]);
          if (data.status == "success") {
            this.UpdateToMarketplaceDataSet.success++;
          } else {
            this.UpdateToMarketplaceDataSet.datasetError.push(item);
            this.UpdateToMarketplaceDataSet.error++;
          }
        }
        
      }
      if (marketplaceName == "lazada") {
        for (const item of this.UpdateToMarketplaceDataSet.dataset) {
          Round++;
          let body = { packageID: item.packageID };
          this.getPercentLoading(Round, countLoop);
          let data = await this.RTS_lazada_package(body);
          this.$set(item, "processing", data.status);
          this.$set(item, "message", data.message[this.set_language]);
          if (data.status == "success") {
            this.UpdateToMarketplaceDataSet.success++;
          } else {
            this.UpdateToMarketplaceDataSet.datasetError.push(item);
            this.UpdateToMarketplaceDataSet.error++;
          }
        }
      }
      if (marketplaceName == "tiktok") {
        for (const item of this.UpdateToMarketplaceDataSet.dataset) {
          Round++;
          let body = {
            packageID: item.packageID,
            handover_method: this.UpdateToMarketplaceDataSet.handover_method,
          };
          this.getPercentLoading(Round, countLoop);
          let data = await this.RTS_tiktok_order(body);

          this.$set(item, "processing", data.status);
          this.$set(item, "message", data.message[this.set_language]);
          if (data.status == "success") {
            this.UpdateToMarketplaceDataSet.success++;
          } else {
            this.UpdateToMarketplaceDataSet.datasetError.push(item);
            this.UpdateToMarketplaceDataSet.error++;
          }
        }
      }
      this.progressBarActive=false;
    },
    async btnPrintAwb() {
     
      console.log(this.dataTable.selected);
      
      const packageIDList = this.dataTable.selected.map(
        (item) => item.orderMasterPackageInfoID
      );
      var PackageSelected = []
      console.log('this.sortedPackageIDList',this.sortedPackageIDList);
      
      this.sortedPackageIDList.forEach(element => {
        if (packageIDList.filter(i => i == element).length > 0) {
          PackageSelected.push(element);
        }
      });
     
      console.log('PackageSelected',PackageSelected);
      
      let data = await this.preparePrintAwb(PackageSelected);
      if (data.orderList != null) {
        this.printAwsDataSet.dataset = await data.orderList;
        if (this.printAwsDataSet.dataset != null && this.printAwsDataSet.dataset.length > 0)
          this.isNeedItemWhenPrint = this.printAwsDataSet.dataset[0].isNeedItemWhenPrint;
        this.printAwsDataSet.success = 0;
        this.printAwsDataSet.error = 0;
        this.printAwsDataSet.datasetError = [];
        this.printAwsPopup.tab = 0;
        this.printAwsPopup.dialog = true;
      }
    },
    async btnSubmitAwb() {
      let dataOUT = [];

      this.printAwsDataSet.loadingPercent = true;
      this.printAwsDataSet.loading = true;
      let Round = 1;
      let countLoop = this.printAwsDataSet.dataset.length + 2;
      this.progressBarActive=true;
      this.getPercentLoading(Round, countLoop);

      for (const item of this.printAwsDataSet.dataset) {
        Round++;
        if (
          (item.referenceSourceID == 3 ||
              item.referenceSourceID == 4 ||
              item.referenceSourceID == 8 ||
              item.referenceSourceID == 11) && !item.isSellerOwnFleet
        ) {
          let data = await this.getAwb(item);
          this.getPercentLoading(Round, countLoop);
          if (data.bytes != null) {
            dataOUT.push(data.bytes);
          } else if (data.base64HTML != null) {
            let bytes = await this.convertBase64HtmlToArrayBuffer(data.base64HTML);

            dataOUT.push(bytes);
          }

          if (data.status == "success") {
            this.$set(item, "processing", await data.status);
            this.printAwsDataSet.success++;
          } else {
            this.$set(item, "processing", "error");
            this.$set(item, "error_description", data.error_description);
            this.printAwsDataSet.datasetError.push(item);
            this.printAwsDataSet.error++;
          }
        } else {
          let data = await this.createShipment(item.orderMasterID, item.packageID);
          this.getPercentLoading(Round, countLoop);
          if (data.status == true && (data.message == null || data.message == "")) {
            dataOUT.push(data);
            this.$set(item, "processing", "success");
            this.printAwsDataSet.success++;
          } else {
            this.$set(item, "processing", "error");
            this.$set(item, "error_description", data.message);
            this.printAwsDataSet.datasetError.push(item);
            this.printAwsDataSet.error++;
          }
        }
      }
      Round++;
   
      if (
        ( this.printAwsDataSet.dataset[0].referenceSourceID == 3 ||
               this.printAwsDataSet.dataset[0].referenceSourceID == 4 ||
               this.printAwsDataSet.dataset[0].referenceSourceID == 8 ||
               this.printAwsDataSet.dataset[0].referenceSourceID == 11) && ! this.printAwsDataSet.dataset[0].isSellerOwnFleet
      ) {
        if (dataOUT.length > 0) {
          this.convertBytesToPDF(dataOUT);
        }
      } else {
        let byteArray = []
        let url=[]
        if (dataOUT.length > 0) {
          dataOUT.forEach(element => {
            if (element.byteArray != null) {
              byteArray.push(element.byteArray);
            }else  if (element.url != null) {
              url.push(element.url);
            }
          });
          if (byteArray.length == dataOUT.length) {
            this.convertBytesToPDF(byteArray);
          }else{
            var key = Math.floor(Math.random() * 1000000 + 1).toString();
            localStorage.setItem("printawb", JSON.stringify(dataOUT));
            sessionStorage.setItem("encodedQueryKey_printAws", key);
            window.open(`/print-awb?key=${key}`);
          }
        }
      }
      this.getPercentLoading(Round, countLoop);
      this.progressBarActive=false;
    },
    async convertBase64HtmlToArrayBuffer(base64Html) {

      const htmlString = decodeURIComponent(escape(atob(base64Html)));
      let iframe = document.createElement("iframe");
      iframe.style.visibility = "hidden";
      document.body.appendChild(iframe);
      let iframedoc = iframe.contentDocument || iframe?.contentWindow?.document;
      if (!iframedoc) return;
      iframedoc.body.innerHTML = htmlString;
      let canvas = await html2canvas(iframedoc.body, {});
      let imgData = canvas.toDataURL("image/png");
      const doc = new jsPDF({
        format: [100, 150],
        unit: "mm",
      });
      doc.addImage(imgData, "PNG", 0, 0, 100, 150);
      document.body.removeChild(iframe);
      return doc.output("arraybuffer");
    },
    async convertBytesToPDF(byteArrays) {
      // สร้างเอกสาร PDF ใหม่
      var pdfDoc = await PDFDocument.create();

      for (let i = 0; i < byteArrays.length; i++) {
        var byteArray = byteArrays[i];
        var buffer = Buffer.from(byteArray, "base64");
        var uint8Array = new Uint8Array(buffer);

        // โหลดเอกสาร PDF
        var pdfDocToAdd = await PDFDocument.load(uint8Array);

        // คัดลอกหน้าจากเอกสาร PDF ที่โหลดมา
        var copiedPages = await pdfDoc.copyPages(
          pdfDocToAdd,
          pdfDocToAdd.getPageIndices()
        );

        // แทรกทุกหน้าลงในเอกสารใหม่
        for (var copiedPage of copiedPages) {
          pdfDoc.addPage(copiedPage);
        }
      }
      // แปลง PDF เป็น bytes
      var pdfBytes = await pdfDoc.save();

      //   // บันทึกเอกสาร PDF
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(blob);

      const newWindow = window.open(pdfUrl);

      newWindow.print();
    },
    async btnAllocateStock() {
      const packageIDList = this.dataTable.selected.map(
        (item) => item.orderMasterPackageInfoID
      );
      let data = await this.prepareBeforeAllocate(packageIDList);

      this.allocateStockDataSet.dataset = await data.packageList;
      this.allocateStockDataSet.success = 0;
      this.allocateStockDataSet.error = 0;
      this.allocateStockDataSet.datasetError = [];
      this.allocateStockDataSet.allocateStockPopup = null;
      this.allocateStockPopup.tab = 0;
      this.allocateStockPopup.dialog = true;
    },
    async btnSubmitAllocateStock() {
      let dataOUT = [];

      this.allocateStockDataSet.loadingPercent = true;
      this.allocateStockDataSet.loading = true;
      let Round = 0;
      let countLoop = this.allocateStockDataSet.dataset.length;
      this.progressBarActive=true;
      for (const item of this.allocateStockDataSet.dataset) {
        Round++;

        let data = await this.allocateStock(
          item.packageID,
          this.allocateStockDataSet.Select
        );
        this.getPercentLoading(Round, countLoop);

        if (data.status == "success") {
          this.$set(item, "processing", await data.status);
          this.allocateStockDataSet.success++;
        } else {
          this.$set(item, "processing", "error");
          this.$set(item, "error_description", data.error_description);
          this.allocateStockDataSet.datasetError.push(item);
          this.allocateStockDataSet.error++;
        }
      }
      this.progressBarActive=false;
      Swal.fire({
        title: "ดำเนินการสำเร็จ",
        text:
          "สำเร็จ " +
          this.allocateStockDataSet.success +
          " รายการ , ไม่สำเร็จ " +
          this.allocateStockDataSet.error +
          " รายการ",
        icon: "success",
      });
      
    },

    //ส่วนของ Alert
    async alertConfirm(obj) {
      return Swal.fire({
        icon: "question",
        text: obj.description,
        confirmButtonText: "ใช้",
        cancelButtonText: "ไม่ใช้",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then((result) => (result.value ? obj : false));
    },

    FormatOrder,
    length60,
    AlertError,
    AlertSuccess,
    formatMoney,
    formatDatetime,
    GetDate,
    UpdateViewTypePage,
    GetViewTypePage,
  },
};
</script>

<style scoped>
.v-tab:not {
  background: rgb(152, 144, 144);
}

.v-tab--active {
  background: white;
}

.v-tab:not(.v-tab--active) {
  background: rgba(62, 60, 60, 0.156);
}

/* >>> .v-tabs-slider-wrapper {
  top: 0px;
} */

.chip-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.textChip-center {
  display: inline-flex;
  align-items: center;
  margin: 0px 5px 0px 0px;
}

.box-shadow {
  border: 1px solid #d7d7d7;
  border-radius: 0px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
  background: white;
}

/* >>> .v-select__slot {
  height: auto;
} */

.overflow-x-auto {
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  /* IE and Edge */
}

.tab-selected {
  background-color: rgb(197, 197, 197);
  /* สีพื้นหลังของแท็บที่ถูกเลือก */
}

.multiline-text {
  white-space: pre-line;
}
</style>
